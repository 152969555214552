<template>
  <div class="flex row filelist">
    <div class="col-10 flex row justify-between">
      <spinner-component v-if="loader" type="Table"></spinner-component>
      <div style="display: none">
        {{ hidequotes }} {{ triggerList }} {{ ErrorCount }}
      </div>
      <q-table
        :expanded="expanded"
        class="col-12 my-sticky-header-last-column-tableList my-sticky-header-table FileTableBottomRow"
        flat
        :rows="RevFileDetails"
        :columns="columns"
        wrap-cells
        row-key="document_name"
        v-if="!loader"
        :pagination="initialPagination"
      >
        <template v-slot:header="props">
          <q-tr
            :props="props"
            class="text-center text-bold text-primary"
            style="white-space: nowrap"
          >
            <q-th style="width: 3%">#</q-th>
            <q-th style="width: 3%" v-for="col in props.cols" :key="col.key">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr
            :props="props"
            :class="{ 'selected-rowFile': props.row === selectedRow }"
            @click="selectRow(props.row), showFilePreview(props.row)"
          >
            <q-td style="width: 3%" class="text-center">{{
              props.rowIndex + 1
            }}</q-td>

            <q-td style="width: 1%" key="name" class="text-center">
              {{ props.row.document_name }}
            </q-td>
            <q-td style="width: 9%" key="type" class="text-center">
              {{ props.row.document_type }}
            </q-td>

            <q-td style="width: 3%" key="versions" class="text-center">
              {{ props.row.document_versions || " " }}
            </q-td>

            <q-td
              style="width: 25%"
              key="status"
              class="text-center extract-status"
            >
              <div v-if="props.row.document_category === 'BOM'">
                <span
                  v-if="props.row.extraction_status == 'Ready for Extraction'"
                >
                  <q-btn
                    style="min-width: 80px"
                    v-if="
                      props.row.showProgress == false ||
                      SpinnerProgress == false
                    "
                    key="props.rowIndex"
                    :disable="
                      props.row.hideextract ||
                      (myStore.userdata.role == 'quoter' &&
                        myStore.partListStatus == true) ||
                      (myStore.userdata.role == 'buyer' &&
                        myStore.partListStatus == false)
                    "
                    dense
                    size="0.8em"
                    :label="
                      props.row.extraction_status == 'Extraction Successful'
                        ? 'Quotes'
                        : 'Extract'
                    "
                    :icon="
                      !props.row.hideextract
                        ? 'fas fa-file-archive'
                        : 'fas fa-ban'
                    "
                    :color="!props.row.hideextract ? 'secondary' : 'negative'"
                    :class="
                      !props.row.hideextract ? 'text-black' : 'text-white'
                    "
                    @click="ExtractButton(props.row)"
                  >
                  </q-btn>
                </span>
                <span
                  v-else-if="
                    props.row.extraction_status === 'Extraction Failed' &&
                    props.row.failedStatus == true
                  "
                >
                  <q-btn
                    class="ExtractionFail display-none1"
                    dense
                    outline
                    color="negative"
                    @click="
                      toggleExpanded(props.row.document_name),
                        ErrorBom(props.row)
                    "
                    style="
                      min-width: 80px;
                      background-color: #069f2d;
                      opacity: 1 !important;
                    "
                    size="0.8em"
                    :icon-right="
                      expanded.length === 0
                        ? 'fas fa-angle-down'
                        : 'fas fa-angle-up'
                    "
                    icon="fa-solid fa-circle-exclamation"
                    label="Error "
                  >
                    <!-- <q-icon name="fas fa-circle-xmark" color="negative" size="1.5em"></q-icon> -->
                  </q-btn>
                </span>
                <span
                  v-else-if="
                    props.row.extraction_status == 'Extraction Successful'
                  "
                >
                  <q-spinner-dots
                    class="fade-in"
                    color="secondary"
                    size="2em"
                    v-show="extracticon"
                  />
                  <q-btn
                    v-show="!extracticon"
                    dense
                    outline
                    color="positive"
                    disabled
                    style="
                      min-width: 80px;
                      background-color: #069f2d;
                      opacity: 1 !important;
                    "
                    size="0.8em"
                  >
                    <q-icon
                      name="fas fa-check-circle"
                      color="positive"
                      size="1.5em"
                    ></q-icon
                  ></q-btn>
                </span>
                <span v-else>{{ props.row.extraction_status }}</span>
                <q-spinner-dots
                  class="fade-in"
                  color="secondary"
                  size="2em"
                  v-if="
                    SpinnerProgress && props.row.document_name === ExtractFile
                  "
                />
              </div>
              <div v-else>
                {{ props.row.extraction_status }}

                <!-- <q-linear-progress light stripe rounded size="8px" :value="progress2" color="red" class="q-mt-sm" /> -->
              </div>
            </q-td>
            <q-td style="width: 15%" key="uploadBy" class="text-center">
              {{
                props.row.uploaded_by.charAt(0).toUpperCase() +
                props.row.uploaded_by.slice(1)
              }}
            </q-td>
            <q-td style="width: 15%" key="uploadDate" class="text-center">
              {{ $filters.formatDate(props.row.created_at) }}
            </q-td>
            <q-td
              style="width: 15%; white-space: nowrap"
              key="actions"
              class="text-center"
            >
              <q-btn
                flat
                dense
                size="sm"
                color="primary"
                @click="getFileByName(props.row.document_name)"
                :disabled="
                  props.row.extraction_status == 'Extraction Successful' ||
                  (myStore.userdata.role == 'quoter' &&
                    myStore.partListStatus == true) ||
                  (myStore.userdata.role == 'buyer' &&
                    myStore.partListStatus == false)
                "
              >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                  >Edit</q-tooltip
                >
                <q-icon name="fas fa-edit" color="info"></q-icon>
              </q-btn>

              <q-btn
                flat
                dense
                size="md"
                color="positive"
                @click="exportClicked(props.row)"
              >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                  >Download</q-tooltip
                >
                <q-icon name="download" color="positive"></q-icon>
              </q-btn>

              <q-btn
                flat
                dense
                size="md"
                color="negative"
                @click="DeleteFile(props.row.document_name)"
                :disable="
                  (myStore.userdata.role == 'quoter' &&
                    myStore.partListStatus == true) ||
                  (myStore.userdata.role == 'buyer' &&
                    myStore.partListStatus == false)
                "
              >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                  >Delete</q-tooltip
                >
                <q-icon name="delete" color="negative"></q-icon>
              </q-btn>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" key="extraction_status">
            <q-td colspan="100%" class="bg-Accordion">
              <div style="padding: 1rem 1rem; width: 50%">
                <div class="row items-start">
                  <ErrorBom
                    :errbomFields="errbomFields"
                    :errbomRows="errbomRows"
                    :fileName="fileNametoDelete"
                    @toggleClose="toggleClose"
                  />
                </div>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="col-2 text-center bg-hover">
      <q-toolbar class="flat bg-primary" v-if="!loader"> Preview </q-toolbar>
      <div class="file-preview q-pa-sm">
        <spinner-component
          v-if="previewLoader"
          type="image"
        ></spinner-component>
        <div v-if="!previewLoader" class="file-preview q-pa-sm">
          <!-- <img :src="filePreview" v-if="filePreview" />
          <img v-else> -->
          <img v-if="this.filePreview === 'PNG'" src="/PNG.png" />
          <img v-else-if="this.filePreview === 'PDF'" src="/PDF.png" />
          <img
            v-else-if="
              this.filePreview === 'JPEG' || this.filePreview === 'JPG'
            "
            src="/JPG.png"
          />
          <img v-else-if="this.filePreview === 'ZIP'" src="/ZIP.png" />
          <img
            v-else-if="
              this.filePreview === 'XLSX' || this.filePreview === 'XLSM'
            "
            src="/XSL.png"
          />
          <img v-else-if="this.filePreview === 'XLS'" src="/XSL.png" />
          <img v-else-if="this.filePreview === 'DOCX'" src="/DOCX.png" />
          <img v-else />

          <div class="text-dark">
            {{ this.filePreview }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="confirm" persistent>
    <q-card
      class="gdialog card-outline-primary confirm-popup"
      v-if="popupType == 'delete'"
    >
      <q-card-section class="card-header-section1">
        <div class="delete-head q-ml-sm">
          <q-icon
            name="report_problem"
            flat
            dense
            color="negative"
            size="xs"
            class="q-mb-xs"
          ></q-icon
          ><span class="q-ml-sm">File Name Delete </span>
        </div>
      </q-card-section>

      <q-card-section class="">
        <span class=""
          >Are you sure you want to delete this File
          {{ fileNametoDelete }} ?</span
        >
      </q-card-section>
      <q-card-actions align="right" class="q-pa-md">
        <q-btn
          label="Ok"
          outline
          color="secondary"
          class="quote-btn"
          @click="handleDelete"
        />
        <q-btn
          label="Cancel"
          outline
          color="secondary"
          class="quote-btn"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
    <q-card
      class="gdialog card-outline-primary"
      style="width: 500px"
      v-if="popupType == 'Rename'"
    >
      <q-form @submit="RenameFile()">
        <q-card-section class="card-header-section1">
          <div class="edit-head q-mx-sm">
            <q-icon flat dense size="xs" name="edit" color="primary"></q-icon
            ><span class="q-ml-sm">Rename File</span>
          </div>
        </q-card-section>

        <q-card-section class="">
          <q-input filled v-model="newFileName" dense :rules="filevalidation" />
        </q-card-section>
        <q-card-actions align="right" class="q-pa-md">
          <q-btn
            label="Save"
            class="quote-btn"
            type="submit"
            outline
            color="secondary"
          />
          <q-btn
            label="Cancel"
            class="quote-btn"
            outline
            color="secondary"
            v-close-popup
            @click="closePopup()"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
  <q-dialog v-model="rename" persistent position="bottom">
    <q-card class="errdialog card-outline-negative" bordered>
      <q-card-section class="q-pa-none">
        <!-- <div class="q-pa-sm row items-start "> -->
        <ErrorBom
          :errbomFields="errbomFields"
          :errbomRows="errbomRows"
          :fileName="fileNametoDelete"
          @toggleClose="toggleClose"
          errtype="errAlert"
        />
        <!-- </div> -->
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { SpinnerComponent } from "@/utils";
import { AlertService, UploadService } from "@/services";
import { router } from "@/helpers";
import { useMyStore } from "@/store";
import ErrorBom from "./ErrorBom";

const columns = [
  {
    name: "document_name",
    required: true,
    label: "File Name",
    align: "left",
    // field: row => row.name,
    // format: val => `${val}`,
    sortable: true,
  },

  {
    name: "document_type",
    label: "File Type",
    field: "document_type",
    sortable: true,
  },
  {
    name: "document_versions",
    align: "center",
    label: "Versions",
    field: "document_versions",
    sortable: true,
  },
  {
    name: "extraction_status",
    label: "Extraction Status",
    field: "extraction_status",
  },
  { name: "uploaded_by", label: "Uploaded By", field: "uploaded_by" },
  { name: "created_at", label: "Uploaded Date", field: "created_at" },
  { name: "document_size", label: "Actions", field: "document_size" },
];

export default {
  components: {
    SpinnerComponent,
    // Vue3Lottie,
    ErrorBom,
  },
  props: ["bom_name"],
  setup() {
    const filevalidation = [(val) => !!val || "File name is Required"];
    const myStore = useMyStore();
    const selectedRow = ref(null);
    const filePreview = ref(null);
    const SpinnerProgress = ref("");
    const ExtractFile = ref("");
    const selectRow = (row) => {
      // Unselect previously selected row
      if (selectedRow.value) {
        selectedRow.value.selected = false;
      }

      // Select the clicked row
      row.selected = true;
      selectedRow.value = row;
      filePreview.value = row.document_type.toUpperCase();
    };

    return {
      myStore,
      filevalidation,
      extracticon: ref(true),
      expanded: ref([]),
      columns,
      selectedRow,
      selectRow,
      filePreview,
      SpinnerProgress,
      ExtractFile,
      loader: ref(true),
      previewLoader: ref(false),
      reversedFileDetails: ref([]),
      initialPagination: {
        sortBy: "desc",
        descending: true,
        page: 1,
        rowsPerPage: 25,
      },
      fileNametoDelete: ref(""),
      confirm: ref(false),
      rename: ref(false),
      clickedFile: ref(""),
      newFileName: ref(""),
      popupType: ref(""),
      // Error Bom
      errbomFields: ref([]),
      errbomRows: ref([]),
    };
  },
  watch: {
    bom_name(newBomName, oldBomName) {
      if (newBomName !== oldBomName) {
        this.getFileDetails();
        this.filePreview = null;
      }
    },
    triggerList(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getFileDetails();
      }
    },
  },
  computed: {
    //After Extract Enable Go to Quotes
    hidequotes() {
      let resultd = this.reversedFileDetails.filter(
        (x) => x.extraction_status == "Extraction Successful"
      );
      var hideextract1 = true;
      if (resultd.length > 0) {
        hideextract1 = false;
      }
      //alert(hideextract)
      this.$emit("hidequotes1", hideextract1);
      return hideextract1;
    },
    ErrorCount() {
      let errlist = this.reversedFileDetails.filter(
        (x) => x.extraction_status == "Extraction Failed"
      );
      this.$emit("ErrorCount", errlist.length);
      return errlist.length;
    },
    //Change the File Details
    RevFileDetails() {
      let resultd = this.reversedFileDetails.filter(
        (x) => x.extraction_status == "Extraction Successful"
      );

      let newBomRows1 = this.reversedFileDetails.map((obj) => {
        {
          obj.showProgress = false;
          obj.buttonStatus = false;
          if (resultd.length > 0) {
            obj.hideextract = true;
          } else {
            obj.hideextract = false;
          }
        }

        return obj;
      });

      return newBomRows1;
    },
    //Trigger new File Upload
    triggerList() {
      return this.myStore.refreshpricing, this.getFileDetails();
    },
  },
  created() {
    this.getFileDetails();
  },

  methods: {
    // Sub Table Error Bom
    toggleExpanded(val) {
      this.fileNametoDelete = val;
      this.expanded = this.expanded[0] === val ? [] : [val];
    },
    toggleClose(value) {
      if (value) {
        this.expanded = [];
      }
    },
    //Export Quote
    async exportClicked(File) {
      try {
        await UploadService.downloadFile(
          this.bom_name,
          File.document_name,
          File.document_type
        ).then((x) => {
          console.log("downloadQuote", x);

          // this.downloadQuote(x, File);
        });
      } catch (error) {
        console.error("Error downloading quote:", error);

        AlertService.Error(error);
      }
    },

    showFilePreview(row) {
      if (
        this.fileNametoDelete != "" &&
        this.fileNametoDelete != row.document_name
      ) {
        this.expanded = [];
      }
      this.previewLoader = true;
      this.filePreview = row.document_type.toUpperCase();
      setTimeout(() => {
        this.previewLoader = false;
      }, 1000);
    },
    //Handle Before & After Extract
    ExtractButton(selectedRow) {
      if (selectedRow.extraction_status == "Extraction Successful")
        return router.push("/");
      else {
        return (
          this.FileExtract(selectedRow.document_name, selectedRow.showProgress),
          this.buttonProgress(selectedRow)
        );
      }
    },
    // to hide Extract button while loading
    buttonProgress(selectedButton) {
      this.RevFileDetails.forEach((row) => {
        if (row.document_name !== selectedButton.document_name) {
          row.showProgress = false;
          row.buttonStatus = true;
        } else {
          row.showProgress = true;
          row.buttonStatus = false;
        }
      });
    },
    //Handling Extract
    async FileExtract(selectedFile, progress) {
      this.ExtractFile = selectedFile;
      this.SpinnerProgress = progress;
      if (this.ExtractFile) {
        this.SpinnerProgress = !this.SpinnerProgress;
        try {
          const x = await UploadService.extract(
            this.bom_name,
            this.ExtractFile
          );
          console.log("res:", x);
          AlertService.Success(this.ExtractFile + "- Extraction Successful");
          this.SpinnerProgress = !this.SpinnerProgress;
          this.getFileDetails();
          this.myStore.refreshpricing = Math.random();
        } catch (error) {
          console.log("error", error.response.status);
          if (error.response.status == 400) {
            this.fileNametoDelete = selectedFile;
            this.SpinnerProgress = !this.SpinnerProgress;
            this.getFileDetails();
            this.ErrorBom();
            this.rename = true;
            this.popupType = "ErrorBom";
          } else {
            AlertService.Error(error.response.data.detail);
            this.fileNametoDelete = selectedFile;
            this.SpinnerProgress = !this.SpinnerProgress;
            this.getFileDetails();
          }
        }
      }
    },
    async getFileDetails() {
      if (
        this.bom_name !== null &&
        this.bom_name !== "" &&
        this.bom_name !== "null"
      ) {
        this.loader = true;
        await UploadService.getFormTable(this.bom_name)
          .then((x) => {
            this.FileDetails = x;
            //Reverse the File
            this.reversedFileDetails = this.FileDetails.reverse();
            if (this.reversedFileDetails.length > 0) {
              this.selectRow(this.reversedFileDetails[0]);
            }
            //Sorting last uploaded First
            this.sortFileDetails = this.reversedFileDetails.sort(
              (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
            );
            if (this.sortFileDetails.length > 0) {
              this.selectRow(this.sortFileDetails[0]);
              this.showFilePreview(this.sortFileDetails[0]);
            }
            this.expanded = [];
            this.sortFileDetails.map(async (row) => {
              if (row.extraction_status == "Extraction Failed") {
                await UploadService.getErrorBom(
                  this.bom_name,
                  row.document_name
                )
                  .then((x) => {
                    this.errbomRows = x;
                    this.errbomFields = this.myStore.fields.errbom_fields;
                    if (this.errbomRows.length > 0) {
                      row.failedStatus = true;
                    }
                  })
                  .catch((err) => console.log(err));
              }
            });
            this.$emit("Toasterfunction", this.sortFileDetails);

            this.loader = false;
          })
          .catch((err) => {
            console.log(err);
            this.loader = false;
          });
      } else {
        this.loader = false;
      }
    },
    //Close the Confirmation
    closePopup() {
      this.rename = false;
    },
    //Handling Rename File
    async RenameFile() {
      const body = {
        new_document_name: this.newFileName,
      };
      await UploadService.Rename(this.bom_name, this.clickedFile, body)
        .then((x) => {
          AlertService.Success(x);
          this.myStore.refreshpricing = Math.random();
          this.confirm = false;
          this.getFileDetails();
        })
        .catch((err) => {
          AlertService.Error(err);
        });
    },
    //Get Individual File Details
    async getFileByName(fileName) {
      this.clickedFile = fileName;
      await UploadService.getFile(this.bom_name, fileName).then((x) => {
        this.newFileName = x.document_name;
        this.confirm = true;
        this.popupType = "Rename";
      });
    },
    //Delete Confirmation
    async DeleteFile(filename) {
      this.fileNametoDelete = filename;
      this.popupType = "delete";
      // Show Quasar Dialog
      this.confirm = true;
    },
    //Handling File Delete
    async handleDelete() {
      try {
        await UploadService.deleteFile(this.bom_name, this.fileNametoDelete);
        AlertService.Success("File Deleted Successfully");
        this.confirm = false;
        this.filePreview = null;
        this.getFileDetails();
        this.myStore.refreshpricing = Math.random();
      } catch (err) {
        // If the API call fails, show error message
        AlertService.Error(err);
        this.confirm = false;
        this.getFileDetails();
      }
    },
    // Handle Error bom Rows
    async ErrorBom() {
      await UploadService.getErrorBom(this.bom_name, this.fileNametoDelete)
        .then((x) => {
          this.errbomRows = x;
          this.errbomFields = this.myStore.fields.errbom_fields;
        })
        .catch((err) => console.log(err));
      setTimeout(() => {
        this.rename = false;
      }, 3000);
    },
  },
  mounted() {
    setTimeout(() => (this.extracticon = false), 1000);
    this.getFileDetails();
    // this.ErrorBom();
  },
};
</script>
<style scoped lang="scss">
.edit-head {
  font-size: 16px;
  font-weight: 500;
  color: #3d4050;
}
.delete-head {
  font-size: 16px;
  font-weight: 500;
  color: #ff4b4b;
}
</style>
