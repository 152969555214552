import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";
import { QuotesService, SettingService } from "./services";
import { metadata } from "./utils";

export const useMyStore = defineStore("myStore", {
  state: () => ({
    fields: ref([]), //fields json
    isCardVisible: ref(true), //hide left panel
    isToastVisible: ref(true), // hide toaster
    CommonTitle: ref(""), //Roles common page title
    dashboardlist: ref([]), //dashboard json
    settingsList: ref([]), // settings module json
    rfqDetails: ref([]), //get rfq details
    GlobalQty: ref(0), //global bom qty
    treeLabels: ref({}), //labels  for oem,product,iteration
    newArray: ref([{ value: "-", is_primary: false, details: "-" }]), //rfq qty array
    bom_name: ref(""), //global bom_name
    partListStatus: ref(false), //bom-part list status
    disablebuttons: ref(false), //Asl Table Disable action buttons
    notifyData: ref({
      uid: "",
      type: "",
      message: "",
      more_info: {
        ipn: "",
        details: [{ change: "up", variance_field: "supplier_stock" }],
        bom_name: "",
        notification_type: "quoting.sourcing.part_updated",
      },
      created_at: "2024-07-17T07:47:14.036354",
    }),
    unreadcount: ref(0), //Global unread count for notification
    copyerrmsg: ref(""), // copy bom name error message
    pbQty: ref(0), // global price break qty
    userdata: ref({
      access_token: null,
      email: null,
      first_name: null,
      last_name: null,
      username: null,
    }), //login user data
    refreshpricing: ref("star"), //common function call
    refreshquotes: ref("test"), // notification common function
    MenuUrl: ref(""), //help menu first url
    NOEM: ref(""), //Global Customer name
    NProduct: ref({ product: "" }), // Global Assembly name
    NIter: ref({ iteration: "" }), // Global Rfq name
    MenuList: ref([]), // help menu json
    ManualInputFields: ref({
      manual: false,
      inputValue: "",
      mpnInput: "",
      SupplierInput: "",
      spnInput: "",
      mult: 0,
      price: 0,
      Moq: 0,
      stock: 0,
      leadTime: 0,
      RequiredQty: "",
      qty: "",
    }),
  }),
  initialize() {
    this.loadPosts();
  },

  actions: {
    async loadPosts() {
      try {
        this.userdata = JSON.parse(localStorage.getItem("cmuser")) || {
          access_token: null,
          email: null,
          first_name: null,
          last_name: null,
          username: null,
        };
        if (this.userdata.access_token) {
          this.bom_name = localStorage.getItem("bom_name");
          this.CommonTitle = metadata[this.userdata.role];
          //View Settings for OEM,Product,Iteration
          SettingService.ViewSettingData().then((x) => {
            this.treeLabels = Object.values(x.treeLabels);
          });
          //set dahsboard id Dynamic (First id)
          const dresponse = await axios.get(
            this.userdata.role == "buyer"
              ? "api/dashboard_buyer.json"
              : "api/dashboard_quoter.json"
          );
          this.dashboardlist = dresponse.data;
          const Qid = this.findFirstSectionId("Quotes");
          const Vid = this.findFirstSectionId("Variancetab");
          const Did = this.findFirstSectionId("Dashboard");
          const Rid = this.findFirstSectionId("Reports");
          const Sid = this.findFirstSectionId("Suppliers");
          const orderId = this.findFirstSectionId("Orders");
          const Oemid = this.findFirstSectionId("OEM");
          const PriceAvailabilityPartId =
            this.findFirstSectionId("PartAlternates");
          var DashData = {
            Qid: Qid,
            Vid: Vid,
            Did: Did,
            Rid: Rid,
            Sid: Sid,
            Oid: orderId,
            Customer: Oemid,
            PriceAvailabilityPartId: PriceAvailabilityPartId,
          };
          localStorage.setItem("DashID", JSON.stringify(DashData));
          //help
          // const helpresponse = await axios.get("api/helpMenu.json");
          const helpresponse = await axios.get(
            this.userdata.role == "buyer"
              ? "api/helpMenu_buyer.json"
              : "api/helpMenu.json"
          );
          this.MenuList = helpresponse.data;
          // this.MenuUrl = this.MenuList["Help"][0].url;
          this.MenuUrl =
            this.userdata.role == "buyer"
              ? this.MenuList["Help"][0].url
              : this.MenuList["Help"][0].url;
          //fields json make Global
          const response = await axios.get(
            this.userdata.role == "buyer"
              ? "api/fields_buyer.json"
              : "api/fields_quoter.json"
          );
          this.fields = response.data;
          // settings module json
          const SettingsRes = await axios.get("setting/settingsJson.json");
          this.settingsList = SettingsRes.data;
        }
        // if (this.bom_name != null || this.bom_name != 'null') {
        //   const PopupNodes = JSON.parse(localStorage.getItem('expandedNodes'))
        //   this.NOEM = PopupNodes[0];
        //   this.NProduct.product = PopupNodes[1]
        //   this.NIter.iteration = localStorage.getItem('bom_name')
        // }
      } catch (error) {
        console.error("Error loading posts:", error);
        throw error;
      }
    },
    findFirstSectionId(module) {
      const moduleData = this.dashboardlist[module];
      if (moduleData) {
        const sections = Object.keys(moduleData);
        if (sections.length > 0) {
          const firstSection = sections[0];
          const firstSectionData = moduleData[firstSection];
          if (firstSectionData && firstSectionData.length > 0) {
            return firstSectionData[0].id;
          }
        }
      }
      return null;
    },

    async loadQuotes() {
      try {
        this.bom_name = localStorage.getItem("bom_name");
        if (this.bom_name != null && this.bom_name != "null") {
          await QuotesService.getRfqDetails(this.bom_name)
            .then((x) => {
              this.rfqDetails = x;
              this.partListStatus = x.is_part_list;
              if (
                x.required_quantity !== null &&
                x.required_quantity !== undefined
              ) {
                this.newArray = Object.keys(x.required_quantity).map(function (
                  key
                ) {
                  return {
                    value: key,
                    is_primary: x.required_quantity[key].is_primary,
                    details: x.required_quantity[key].details,
                  };
                });
                if (localStorage.getItem("qty") !== "null") {
                  this.GlobalQty = localStorage.getItem("qty");
                } else {
                  this.GlobalQty = this.newArray.find(
                    (qty) => qty.is_primary
                  )?.value;
                  localStorage.setItem("qty", this.GlobalQty);
                  var arr = [];
                  arr.push(this.bom_name), arr.push(this.GlobalQty);
                  localStorage.setItem("bomname-qty", JSON.stringify(arr));
                }
              } else {
                this.newArray = [
                  { value: "-", is_primary: false, details: "-" },
                ];
              }
            })
            .catch((err) => {
              console.log("err...", err);
              this.newArray = [{ value: "-", is_primary: false, details: "-" }];
            });
        }
      } catch (error) {
        console.error("Error loading posts:", error);
        throw error;
      }
    },
  },
  watch: {
    bom_name(newBom, oldBom) {
      if (newBom != oldBom) {
        this.loadPosts();
      }
    },
  },
});
