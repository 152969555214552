<template>
  <header class="tab-header">
    <aside class="left-menus">
      <q-btn
        @click="lifecyclestatus('Active')"
        class="left-btn mark-active"
        :disable="
          !EnableOnSelection ||
          watchlistRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Active'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as <strong>active</strong>
        </q-tooltip>
      </q-btn>
      <q-btn
        @click="lifecyclestatus('Obselete')"
        class="left-btn mark-obsolete"
        :disable="
          !EnableOnSelection ||
          watchlistRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Obselete'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as <strong>obsolete</strong>
        </q-tooltip>
      </q-btn>
      <button class="left-btn add-watchlist" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add to <strong>watchlist</strong>
        </q-tooltip>
      </button>
      <q-btn
        @click="Removewatchlist()"
        class="left-btn remove-watchlist"
        :disable="!EnableOnSelection"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Remove from <strong>watchlist</strong>
        </q-tooltip>
      </q-btn>
    </aside>

    <aside class="search-wrap">
      <q-input v-model="search" outlined placeholder="Search">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <!-- <section class="search-suggestions">
        <p>Looking for</p>
        <aside class="search-tags">
          <li>Last search</li>
          <li>Frequently used parts</li>
          <li>High cost parts</li>
        </aside>
      </section> -->
    </aside>

    <aside class="right-menu">
      <!-- <q-btn
        @click="popupbox = true"
        class="right-btn add-btn"
        :disable="!EnableOnSelection"
      > -->
      <q-btn @click="popupbox = true" class="right-btn add-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          <strong>Add</strong>
        </q-tooltip>
      </q-btn>
      <button class="right-btn update-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Update <strong>pricing</strong>
        </q-tooltip>
      </button>
      <button class="right-btn delete-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Delete
        </q-tooltip>
      </button>
      <button class="right-btn upload-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Upload
        </q-tooltip>
      </button>
      <button class="right-btn download-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Download
        </q-tooltip>
      </button>
    </aside>

    <button class="more-mob-btn">
      <q-icon name="more_vert" color="grey" class="more-mob-icon" />
      <q-menu>
        <q-list class="mob-btn-flex">
          <q-btn @click="popupbox = true" class="right-btn add-btn">
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              <strong>Add</strong>
            </q-tooltip>
          </q-btn>
          <button class="right-btn update-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Update <strong>pricing</strong>
            </q-tooltip>
          </button>
          <button class="right-btn delete-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Delete
            </q-tooltip>
          </button>
          <button class="right-btn upload-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Upload
            </q-tooltip>
          </button>
          <button class="right-btn download-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Download
            </q-tooltip>
          </button>
        </q-list>
      </q-menu>
    </button>
  </header>

  <section class="table-wrap">
    <SpinnerComponent type="Table" v-if="loading" />
    <q-markup-table
      v-if="!loading"
      class="TableHeight-partlib my-sticky-header-table"
    >
      <thead>
        <tr>
          <th
            class="text-left"
            v-for="(col, index) in watchlistFields"
            :key="index"
          >
            <span
              v-if="col.key === 'ipn'"
              @click="sortDataBy('ipn')"
              style="cursor: pointer"
            >
              {{ col.label }}
              <q-icon
                :name="
                  sortBy === 'ipn' && sortOrder === 'asc'
                    ? 'arrow_upward'
                    : 'arrow_downward'
                "
              />
            </span>
            <span
              v-else-if="col.key === 'mpn'"
              @click="sortDataBy('mpn')"
              style="cursor: pointer"
            >
              {{ col.label }}
              <q-icon
                :name="
                  sortBy === 'mpn' && sortOrder === 'asc'
                    ? 'arrow_upward'
                    : 'arrow_downward'
                "
              />
            </span>
            <span
              v-else-if="col.key === 'manufacturer'"
              @click="sortDataBy('manufacturer')"
              style="cursor: pointer"
            >
              {{ col.label }}
              <q-icon
                :name="
                  sortBy === 'manufacturer' && sortOrder === 'asc'
                    ? 'arrow_upward'
                    : 'arrow_downward'
                "
              />
            </span>
            <span
              v-else-if="col.key === 'category'"
              @click="sortDataBy('category')"
              style="cursor: pointer"
            >
              {{ col.label }}
              <q-icon
                :name="
                  sortBy === 'category' && sortOrder === 'asc'
                    ? 'arrow_upward'
                    : 'arrow_downward'
                "
              />
            </span>
            <span v-else>
              {{ col.label }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(part, index) in data" :key="index">
        <tr class="text-left">
          <td
            class="text-left"
            v-for="(col, col_index) in watchlistFields"
            :key="col_index"
          >
            <span v-if="col.key == 'index'">
              <q-checkbox
                :model-value="isPartSelected(part)"
                size="xs"
                @update:model-value="toggleSelection(part)"
                class="q-ml-sm q-mb-md partlib"
              />
            </span>

            <span v-else-if="col.key == 'image'">
              <div class="table-img">
                <img
                  v-if="
                    part.image_url != '' &&
                    part.image_url != null &&
                    part.image_url != 'None'
                  "
                  :src="part.image_url"
                />
              </div>
            </span>
            <span v-else-if="col.key == 'description'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'category'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'created_at'" class="active-td">
              <span v-if="part.lifecycle_status == 'Obselete'">
                <img src="@/assets/obsolete-icon.svg" alt="" />
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-5, -5]"
                >
                  Obsolete
                </q-tooltip>
              </span>
              <span v-else>
                <h5>Active</h5>
                <h6>
                  {{
                    part[col.key] != null
                      ? formatActivesince(part[col.key])
                      : "-"
                  }}
                </h6>
              </span>
            </span>

            <span v-else-if="col.key == 'watchlist_tags'">
              <span v-for="(sour, skey) in part[col.key]" :key="skey">
                <q-badge
                  rounded
                  class="text-primary BestRecommended q-mr-xs q-mb-xs"
                  >{{ skey }}</q-badge
                >
              </span>
            </span>

            <span v-else-if="col.key == 'unread_notification_count'">
              <q-btn
                dense
                round
                flat
                color="primary"
                icon="notifications"
                @click="openNotification(part)"
              >
                <q-badge
                  v-if="part[col.key] > 0"
                  color="red"
                  floating
                  transparent
                  style="font-size: 9px"
                >
                  {{ part[col.key] }}</q-badge
                >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                >
                  Unread Notifications
                </q-tooltip>
              </q-btn>
            </span>

            <span v-else>{{ part[col.key] }}</span>
          </td>
        </tr>
      </tbody>
    </q-markup-table>
    <div v-if="watchlistRows.length === 0 && !loading">
      <div class="full-width row flex-center text-black q-gutter-sm">
        <q-icon size="1.5em" name="report_problem" />
        <span> No Data Found </span>
      </div>
    </div>
    <div
      class="row justify-between"
      style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px"
      v-if="!loading && watchlistRows.length > 0"
    >
      <div class="q-ml-lg text-grey-8 hint-fullscreen">
        <!-- <b>Tip: &nbsp;</b> Press &nbsp;<span  class="fullscreenHintText"> Shift + F</span> &nbsp; {{ toggleFullscreenTip }} -->
      </div>
      <div class="flex" style="font-size: 12px">
        <div
          class="cursor-pointer q-mr-sm q-pa-xs"
          style="font-size: 12px; margin-top: 3px"
          dense
          color="primary"
        >
          Records per page&nbsp;:&nbsp;
          <q-btn
            style="font-size: 12px"
            class="btn--no-hover q-pa-none"
            flat
            dense
            :label="
              pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage
            "
            icon-right="fa-solid fa-caret-down"
            color="primary"
          >
            <q-menu>
              <q-list
                dense
                v-for="(pag, index) in paginationOptions"
                :key="index"
              >
                <q-item
                  clickable
                  v-close-popup
                  @click="selectpagination(pag)"
                  class="q-pa-none"
                >
                  <q-item-section> {{ pag }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <q-pagination
          v-model="pagination.page"
          :max="pagesNumber"
          input
          class="paginationclass q-mr-sm"
          size="sm"
          direction-links
          boundary-links
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div>
    </div>
  </section>
  <q-dialog v-model="popupbox" persistent>
    <div>
      <AddWatchlistTag :closeDialog="closeDialog" />
    </div>
  </q-dialog>
  <NotificationDialog ref="notificationDialog"> </NotificationDialog>
</template>
<script>
import { ref } from "vue";
import { useMyStore } from "@/store";
import { AlterPartService, AlertService } from "@/services";
import { SpinnerComponent, commonFunction } from "@/utils";
import moment from "moment";
import AddWatchlistTag from "./AddWatchlistTag.vue";
import NotificationDialog from "@/pages/NotificationDialog.vue";

export default {
  setup() {
    const myStore = useMyStore();
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });
    const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, "All"]);
    const debounceTimeout = ref(null);
    return {
      pagination,
      paginationOptions,
      myStore,
      search: ref(""),
      watchlistRows: ref([]),
      loading: ref(false),
      popupbox: ref(false),
      debounceTimeout,
    };
  },
  components: {
    SpinnerComponent,
    AddWatchlistTag,
    NotificationDialog,
  },
  watch: {
    search(newval) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (newval) {
          this.searchingWatchlist();
        } else {
          this.watchlist();
        }
      }, 500);
    },
  },
  computed: {
    watchlistFields() {
      return this.myStore.fields.watchlist;
    },
    pagesNumber() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.watchlistRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.watchlistRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    data() {
      if (this.pagination.rowsPerPage == -1) {
        return this.watchlistRows;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.watchlistRows.slice(startIndex, endIndex);
      }
    },
    EnableOnSelection() {
      return (
        this.watchlistRows.some((row) => row.selectedRow) &&
        this.selectedParts.length <= 1
      );
    },
    isRowSelected() {
      return this.watchlistRows.some((row) => row.selectedRow);
    },
  },
  methods: {
    openNotification(item) {
      this.$refs.notificationDialog.open(item, true);
    },
    async watchlist() {
      //get all watch list
      this.loading = true;
      await AlterPartService.getwatchlist()
        .then((x) => {
          this.watchlistRows = x.sort(
            (a, b) => new Date(b["updated_at"]) - new Date(a["updated_at"])
          );
          this.watchlistRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
          this.originalRows = [...this.watchlistRows];

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.watchlistRows = [];
          this.loading = false;
        });
    },
    cleanupWords(description) {
      //common func for formatting description & category
      const words = commonFunction.descriptionFormat(description);
      return words;
    },
    formatActivesince(date) {
      //format the date
      const fdate = moment(date).format("YYYY/MM/DD");
      return fdate;
    },
    selectpagination(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.pagination.rowsPerPage = -1;
      } else {
        this.pagination.rowsPerPage = parseInt(value);
      }
      this.pagination.page = 1;
    },
    UpdateRow(row) {
      //update the selected row
      this.watchlistRows.forEach((data) => {
        if (data.ipnIndex == row.ipnIndex) {
          data.selectedRow = !data.selectedRow;
        } else {
          data.selectedRow = false;
        }
      });
    },

    async Removewatchlist() {
      const firstTag = this.myStore.userdata.username;
      await AlterPartService.removewatchlist(
        this.selectedParts[0]?.ipn,
        this.selectedParts[0]?.mpn,
        this.selectedParts[0]?.manufacturer,
        firstTag
      )
        .then(() => {
          AlertService.Success("Removed to Watchlist");
          this.selectedParts = [];
          this.watchlist();
        })
        .catch((err) => {
          AlertService.Error(err);
        });
    },
    async lifecyclestatus(status) {
      const result = this.watchlistRows.find((row) => row.selectedRow === true);
      if (result) {
        const { ipn, mpn, manufacturer } = result;
        await AlterPartService.updatepartlifecycle(
          ipn,
          mpn,
          manufacturer,
          status
        )
          .then((x) => {
            AlertService.Success(x);
            this.selectedParts = [];
            this.watchlist();
          })
          .catch((err) => {
            AlertService.Error(err);
          });
      } else {
        AlertService.Error("Please Select one row to update  Status");
      }
    },
    closeDialog() {
      this.popupbox = false;
    },
    isPartSelected(part) {
      return this.selectedParts.includes(part);
    },
    toggleSelection(part) {
      if (this.isPartSelected(part)) {
        this.watchlistRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts = this.selectedParts.filter(
          (selectedPart) => selectedPart !== part
        );
      } else {
        this.watchlistRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts.push(part);
      }
    },
    async searchingWatchlist() {
      await AlterPartService.searchWatchlist(this.search)
        .then((x) => {
          this.watchlistRows = x.sort(
            (a, b) => new Date(b["updated_at"]) - new Date(a["updated_at"])
          );
          this.watchlistRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.watchlistRows = [];
          this.loading = false;
        });
    },
    sortDataBy(column) {
      if (this.sortBy === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortBy = column;
        this.sortOrder = "desc";
      }
      this.watchlistRows.sort((a, b) => {
        const valA = a[column] ? a[column].toString().toLowerCase() : "";
        const valB = b[column] ? b[column].toString().toLowerCase() : "";

        if (this.sortOrder === "asc") {
          return valA.localeCompare(valB);
        } else if (this.sortOrder === "desc") {
          return valB.localeCompare(valA);
        }
      });
    },
  },
  data() {
    return {
      selectedParts: [], // Array to keep track of selected rows
      sortBy: "",
      sortOrder: "desc",
      originalRows: [],
    };
  },
  mounted() {
    this.watchlist();
  },
};
</script>
