<template>
  <q-dialog
    v-model="dialog"
    :position="position"
    full-height
    class="sidepanel-outerwrap"
  >
    <q-card style="width: 1200px" class="side-panel-wrap">
      <header class="part-detail-title">
        <h1>
          <q-icon name="category" class="link-icon" />
          {{ dialogTitle }}
        </h1>

        <q-btn dense flat color="negative" icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </header>

      <section id="home-wrap">
        <q-tabs
          v-model="tab"
          dense
          class="home-tab-bg"
          active-color="primary"
          indicator-color="purple"
          align="justify"
        >
          <q-tab name="tables" label="ASL" />

          <q-tab name="rfqlist" label="RFQ / Part list"/>

          <q-tab name="charts" label="Trends" />
        </q-tabs>

        <q-tab-panels v-model="tab" animated class="drawer-tab-panel">
          <q-tab-panel name="tables">
            <section class="bg-Accordion">
              <PartAlterSubTable
                :AlterSubParts="AlterSubParts"
                @toggleClose="toggleClose(item.rowIndex)"
              />
            </section>
          </q-tab-panel>
          <q-tab-panel name = "rfqlist">
            <section class="rfq-list-wrap">
              <h6>RFQ / Part list</h6>
              <q-markup-table>
                <thead>
                  <th
                    v-for="col in associatedBomsColumns"
                    :class="'text-' + col.align"
                    :key="col.label"
                  >
                    <span>
                      {{ col.label }}
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(bom, index) in associatedBomdata" :key="index">
                    <td class="text-left">{{ bom.index }}</td>
                    <td class="text-left">{{ bom.customer }}</td>
                    <td class="text-left">{{ bom.assembly }}</td>
                    <td class="text-left">{{ bom.bom_name }}</td>
                    <td class="text-right">
                      <q-icon
                        name="link"
                        class="link-icon"
                        @click="navigateToNotification(bom)"
                      />
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
              <div v-if="associatedBoms.length === 0 && !loader">
                <div
                  class="full-width row flex-center text-black q-pa-sm q-gutter-sm"
                >
                  <q-icon size="1.5em" name="report_problem" />
                  <span> No Data Found </span>
                </div>
              </div>
              <div
                class="row justify-between q-mt-sm"
                style="
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                "
                v-if="!loader && associatedBoms.length > 0"
              >
                <div class="q-ml-lg text-grey-8 hint-fullscreen"></div>
                <div class="flex" style="font-size: 12px; margin-top: -3px">
                  <div
                    class="cursor-pointer q-mr-sm q-mt-xs q-pa-xs"
                    style="font-size: 12px"
                    dense
                    color="primary"
                  >
                    Records per page&nbsp;:&nbsp;
                    <q-btn
                      style="font-size: 12px"
                      class="btn--no-hover q-pa-none"
                      flat
                      dense
                      :label="
                        paginationAssciatedBoms.rowsPerPage === -1
                          ? 'All'
                          : paginationAssciatedBoms.rowsPerPage
                      "
                      color="primary"
                    >
                      <q-menu>
                        <q-list
                          dense
                          v-for="(pag, index) in paginationOptionsAssciatedBoms"
                          :key="index"
                        >
                          <q-item
                            clickable
                            v-close-popup
                            @click="selectpaginationAssociatedBom(pag)"
                            class="q-pa-none"
                          >
                            <q-item-section> {{ pag }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                  <q-pagination
                    v-model="paginationAssciatedBoms.page"
                    :max="pagesNumberAssociatedBoms"
                    input
                    class="paginationclass q-mr-sm"
                    size="sm"
                    direction-links
                    boundary-links
                    icon-first="skip_previous"
                    icon-last="skip_next"
                    icon-prev="fast_rewind"
                    icon-next="fast_forward"
                  />
                </div>
              </div>
            </section>
          </q-tab-panel>

          <q-tab-panel name="charts">
            <section class="trend-chart-wrap q-mb-md">
              <SuperSet
                :dashid="dashid"
                type="Dashboard"
                :bom_name="null"
                :mpn="openMPN"
              />
            </section>
          </q-tab-panel>
        </q-tab-panels>
      </section>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, defineAsyncComponent } from "vue";
import SuperSet from "@/components/quotessummary/SuperSet.vue";
import { AlterPartService, AlertService, UploadService } from "@/services";
import { useMyStore } from "@/store";
import { commonFunction } from "@/utils";
import { Loading } from "quasar";

const rows = ref([]);
const columns = ref([]);
export default {
  props: {
    selectedPartDetails: Object,
  },
  setup() {
    const paginationAssciatedBoms = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 20,
    });
    const paginationOptionsAssciatedBoms = ref([2, 10, 15, 20, 25, 50, "All"]);
    const myStore = useMyStore();
    const dialog = ref(false);
    const position = ref("top");
    const associatedBomsColumns = ref([]);

    return {
      tab: ref("tables"),
      dashid: ref(""),
      position,
      dialog,
      mpnIndex: ref(""),
      columns,
      associatedBomsColumns,
      paginationAssciatedBoms,
      paginationOptionsAssciatedBoms,
      rows,
      myStore,
      loader: ref(false),
      AlterSubParts: ref([]),
      selectedRowID: ref({}),
      isSubTableOpen: ref({}),
    };
  },
  components: {
    PartAlterSubTable: defineAsyncComponent(() =>
      import("./PartAlterSubTable.vue")
    ),
    SuperSet,
  },
  watch: {
    selectedIDRow: function (val) {
      if (this.selectedRowID != null) {
        this.$set(this.selectedRowID, "_index", val);
      }
    },
  },
  computed: {
    associatedBomdata() {
      if (this.paginationAssciatedBoms.rowsPerPage == -1) {
        return this.associatedBoms;
      } else {
        const startIndex =
          (this.paginationAssciatedBoms.page - 1) *
          this.paginationAssciatedBoms.rowsPerPage;
        const endIndex = startIndex + this.paginationAssciatedBoms.rowsPerPage;
        return this.associatedBoms.slice(startIndex, endIndex);
      }
    },
    pagesNumberAssociatedBoms() {
      if (this.paginationAssciatedBoms.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.associatedBoms.length / this.paginationAssciatedBoms.rowsPerPage
        );
        return newpage;
      } else if (
        this.paginationAssciatedBoms.rowsPerPage != -1 &&
        this.search !== ""
      ) {
        const newpage = Math.ceil(
          this.associatedBoms.length / this.paginationAssciatedBoms.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
  },
  methods: {
    async navigateToNotification(data) {
      var treeNode = [];
      Loading.show();
      await UploadService.getStaticTree().then((x) => {
        treeNode = x;
        const iterationNames = treeNode.flatMap((oem) =>
          oem.children.flatMap((product) =>
            product.children
              .filter((child) => child.type === "iteration")
              .map((iteration) => iteration.name)
          )
        );
        if (iterationNames.includes(data.bom_name)) {
          const resultNodes = commonFunction.newfindoemproduct(
            treeNode,
            data.bom_name
          );
          if (resultNodes) {
            var r1 = [];
            r1.push(resultNodes.oemName), r1.push(resultNodes.productName);
            localStorage.setItem("bom_name", resultNodes.iterationName);
            localStorage.setItem("expandedNodes", JSON.stringify(r1));
            localStorage.setItem("qty", null);
            localStorage.setItem("ipn", null);
            var quotes = {
              GroupBy: "none",
              GroupName: "None",
              Filter: { name: "All", value: "all", id: 1, selected: false },
              Filter_Field: "",
            };
            localStorage.setItem("quotespage", JSON.stringify(quotes));
            this.myStore.loadQuotes();
            this.myStore.refreshquotes = Math.random();
            Loading.hide();
            this.$router.push(
              this.myStore.userdata.role == "buyer" ? "/partlist" : "/quotes"
            );
          } else {
            console.log("No data found for the given iteration name.");
          }
        } else {
          Loading.hide();
          AlertService.Error("Item Not Found");
          this.myStore.refreshquotes = Math.random();
        }
      });
    },
    selectpaginationAssociatedBom(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.paginpaginationAssciatedBomsation.rowsPerPage = -1;
      } else {
        this.paginationAssciatedBoms.rowsPerPage = parseInt(value);
      }
      this.paginationAssciatedBoms.page = 1;
    },
    closeSearch() {
      this.search = "";
      this.isSearchOpen = false;
    },
    openSearch() {
      this.isSearchOpen = true;
      this.$nextTick(() => {
        this.$refs.searchInput.$el.querySelector("input").focus();
      });
    },
    open(pos, item) {
      this.position = pos;
      this.dialogTitle = item.mpn;
      this.dialog = true;
      this.openMPN = item.mpn;
      this.paginationAssciatedBoms.page = 1;

      this.getpartsSubTable(item);
      this.getAssociatedBoms(item);
    },
    async getpartsSubTable(row) {
      // Fetch and set subtable data
      try {
        const data = await AlterPartService.getalterSubParts(
          row.mpn,
          row.manufacturer
        );
        this.AlterSubParts = data;
      } catch (err) {
        console.error(err);
      }
    },
    toggleClose(value) {
      //Toggle close subtable
      if (value) {
        this.newrow.map((item) => {
          if (item.mpn === this.openMPN) {
            this.isSubTableOpen[value] = false;
          }
        });
      }
    },
    async getAssociatedBoms(item) {
      this.loader = true;
      const ipn = this.selectedPartDetails.ipn || item.ipn;
      const mpn = item.mpn;
      const manufacturer = item.manufacturer;
      try {
        const x = await AlterPartService.getAssociatedBoms(
          ipn,
          mpn,
          manufacturer
        );
        this.associatedBoms = x.map((bom, index) => ({
          ...bom,
          index: index + 1,
        }));

        this.loader = false;
      } catch (err) {
        console.log(err);
        this.loader = false;
        this.associatedBoms = [];
      }
    },
  },
  mounted() {
    this.associatedBomsColumns = this.myStore.fields.associatedBom;
    var moduleData = JSON.parse(localStorage.getItem("DashID"));
    this.dashid = moduleData.PriceAvailabilityPartId;
  },
  data() {
    return {
      openMPN: null, // Track the currently open row's mpnIndex
      associatedBoms: [],
      dialogTitle: "",
    };
  },
};
</script>
<style scoped lang="scss">
.side-panel-wrap {
  background-color: #fff;
}
.part-detail-title {
  border-bottom: solid 1px #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  h1 {
    margin: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    color: #3d4050;
  }
}
.drawer-tab-panel {
  border: solid 1px #e9e9e9 !important;

  padding: 15px;

  border-radius: 0px 0px 10px 10px !important;
}
.trend-chart-wrap {
  border-radius: 10px;
  background-color: #f6f6f7;
  border: solid 1px #e9e9e9;
  padding: 15px;

  h2 {
    color: #3d4050;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 15px;
  }
}
.rfq-list-wrap {
  margin-top: 15px;
  h6 {
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    color: var(--primary);
    margin-bottom: 5px;
  }
}
.link-icon {
  font-size: 20px;
  transform: rotate(125deg);
  color: #0690bd;
}
</style>
