 
import Notify from 'quasar/src/plugins/Notify.js';


export const AlertService = {
    Success,
    Error
}
function Success(message,position,module) {
    return Notify.create({
        // spinner: QSpinnerHearts,
        message,
        icon: position?'report_problem':'done',
        position: position?position:'bottom',
         actions: module? [
                { label: 'Refresh', color: 'white', handler: () => { /* console.log('wooow') */ } }
              ]:'',
        color: 'positive',
        multiLine: true,
        classes: 'custom-notification-class' 

    })
}


function Error(message) {
    return Notify.create({
        // spinner: QSpinnerBall,
        message,
        icon: 'error',
         
        color: 'negative',
        // position: 'top',
        multiLine: true,
        // actions: [
        //     { icon: 'close', color: 'white', round: true, handler: () => {} }
        //   ] 
        // textColor: "negative"
    })
}
