<template>
    <section class="my-card  bg-upload"  :class="{ 'uploadbtn-section': showModal }" flat bordered>
        <aside vertical>

            <q-tooltip anchor="top middle" self="bottom middle" :offset="[-20, -20]">{{ (myStore.userdata.role == 'quoter'
                && myStore.partListStatus == true) || (myStore.userdata.role == 'buyer' && myStore.partListStatus == false)
                ? 'Upload previllage is not applicable' :'Supported File Formats: XLSX,XLS,PDF,JPG,PNG,ZIP,JPEG'
                }}</q-tooltip>

            <q-file v-model="file" borderless id="file-input" ref="fileInput" class=""
                @update:model-value="filepicked(bom_name, file)" max-files="15" max-file-size="10240000"
                @rejected="onRejected" label="Drop files here Or Click to upload" color="hover-bg"
                :disable="bom_name == 'null' || bom_name == null || (myStore.userdata.role == 'quoter' && myStore.partListStatus == true) || (myStore.userdata.role == 'buyer' && myStore.partListStatus == false)">
                <q-linear-progress v-if="loading" dark size="30px" :value="uploadProgress" color="positive">
                    <div class="absolute-full flex flex-center">
                        <q-badge color="white" text-color="primary" :label="progressBarLabel" />
                    </div>

                </q-linear-progress>
            </q-file>

            <q-icon name="cloud_upload" size="sm" class="upload-icon" color="secondary" @click="$refs.fileInput.pickFiles()" />
            <section class="uploadbtns">

                <q-btn v-if="showModal" class="replace-btn" @click="handleReplace(file)" dense>Replace</q-btn>
                <q-btn v-if="showModal" class="skip-btn" @click.stop.prevent="file = null, showModal = false"
                    dense>Skip</q-btn>

            </section>

            <!-- <q-card-actions vertical class="justify-around q-px-md dollar" v-if="!showModal">
                <q-btn v-if="!showModal" size="xs" round color="primary" icon="question_mark"
                    @click.stop.prevent="file = null, showModal = false" />
            </q-card-actions> -->
        </aside>
    </section>
    <q-dialog v-model="confirm">
        <q-card class="gdialog card-outline-primary  confirm-popup">
            <q-card-section class="card-header-section1">
                <div class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense color="warning" size="sm"
                        class="q-mb-xs"></q-icon><span class="q-ml-sm title-md">File Replace Confirmation </span> </div>
            </q-card-section>

            <q-card-section class="">

                <span class="descrpn-md">Are you sure you want to replace this file {{ newFile }}?</span>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn label="Ok" class="quote-btn" @click="handleReplaceFile" />
                <q-btn label="Cancel" class="quote-btn" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import { AlertService, UploadService } from '../../services'
import { Loading } from 'quasar'
import { useMyStore } from '@/store'

export default {
    name: 'FilePart',
    props: ["bom_name"],
    components: {
    },
    setup() {
        const file = ref(null)
        const loading = ref(false)
        const uploadProgress = ref(0)
        const progressBarLabel = ref("0%")
        const uploadProgressColor = ref('')
        const showModal = ref(false)
        const reversedFileDetails = ref([]);
        const newFile = ref(null);
        const myStore = useMyStore()


        watch(() => file.value, () => {
            uploadProgress.value = 0
            progressBarLabel.value = "0%"
        })

        return {
            myStore,
            file,
            loading,
            uploadProgress,
            // filepicked,
            uploadProgressColor,
            progressBarLabel,
            newFile,
            showModal,
            reversedFileDetails,
            limitedFiles: ref(''),
            confirm: ref(false),
            rFile: ref('')
        }
    },
    watch: {
        bom_name(newBom, oldBom) {
            if (newBom != oldBom) {
                this.getFiledetails()
            }

        }
    },

    methods: {
        //File Rejected Function
        onRejected() {
            AlertService.Error('Upload Valid size File')

        },
        //File Upload Function
        async filepicked(bom_name, file) {
            if (this.showModal == true) {
                this.showModal = false
            }
            //Handling Limited Files
            if (this.limitedFiles >= 15) {
                this.file = null
                AlertService.Error("No of Files Reached the Limit")
            } else {
                //Handling Allowed Extensions
                const allowedExtensions = ['xlsx', 'xls', 'jpeg', 'zip', 'pdf', 'jpg', 'png'];
                const fileExtension = file.name.toLowerCase().slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
                const fileName = file.name.split(".")
                this.newFile = fileName[0]
                this.loading = true;

                const formData = new FormData();
                formData.append('file', file);

                try {
                    if (!allowedExtensions.includes(fileExtension)) {
                        AlertService.Error("Invalid file format.");
                        this.file = null
                        this.loading = false
                    } else {
                        Loading.show();
                        await UploadService.UploadFile(bom_name, formData, {
                            //Progress Bar Calculations
                            onUploadProgress: (progressEvent) => {
                                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                this.uploadProgress = percentage;
                                this.progressBarLabel = `${percentage}%`;
                                this.uploadProgressColor = 'positive';
                            },
                        });
                        this.uploadProgressColor = 'positive';
                        // File upload success
                        this.loading = false;
                        setTimeout(() => {

                            AlertService.Success("File Uploaded Successfully");
                            this.getFiledetails()
                            Loading.hide();
                        }, "1000");
                        this.myStore.refreshpricing = Math.random()
                        this.file = null
                    }

                }

                catch (err) {
                    // File upload error
                    this.uploadProgressColor = 'negative';
                    this.loading = false;
                    Loading.hide();
                    AlertService.Error(err.response.data.detail);
                    this.showModal = true;
                    // this.existingFile =
                    //     this.reversedFileDetails.map(fileDetail => {
                    //         if (fileDetail.document_name === this.newFile && fileDetail.document_type === fileExtension) return fileDetail

                    //     });

                    // return this.existingFile;
                }
            }
        },

        //Handling File Replace Confirmation
        async handleReplace(File) {
            this.rFile = File
            // Show Quasar Dialog
            this.confirm = true;
        },
        //Handling File Replace Function
        async handleReplaceFile() {

            const replaceFile = this.reversedFileDetails.filter(detail =>
                detail.extraction_status == 'Extraction Successful').map(doc => doc.document_name)
            const rfile1 = this.rFile.name.split(".")
            if (replaceFile == rfile1[0]) {
                AlertService.Error('File Already Extracted');
                this.confirm = false
                this.showModal = false;
                this.file = null
            }
            else {
                Loading.show();
                this.confirm = false
                this.showModal = false;
                const fileName = this.rFile.name.split(".")
                const ChangeFile = fileName[0]
                this.loading = true

                const formData = new FormData()
                formData.append('file', this.rFile)

                try {
                    //Loading.show();
                    await UploadService.ReplaceFile(this.bom_name, ChangeFile, formData, {
                        onUploadProgress: (progressEvent) => {
                            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            this.uploadProgress = percentage
                            this.progressBarLabel = `${percentage}%`
                        },
                    })
                    setTimeout(() => {
                        Loading.hide();
                        AlertService.Success("File Replaced Successfully")
                        this.confirm = false
                    }, "1000");
                    this.myStore.refreshpricing = Math.random()
                    this.loading = false
                    this.confirm = false
                    this.file = null
                } catch (err) {
                    Loading.hide();
                    this.loading = false
                    this.confirm = false
                    this.showModal = true
                    AlertService.Error(err.response.data.detail)
                }


            }

        },
        //Handle Skip
        handleSkip() {

            this.showModal = false;
            this.file = null

        },
        //Get Uploaded File Details
        async getFiledetails() {
            if (this.bom_name != null && this.bom_name != 'null' && this.bom_name != '') {
                await UploadService.getFormTable(this.bom_name).then(x => {
                    this.limitedFiles = x.length
                    this.reversedFileDetails = x.reverse();
                }).catch((err) => { console.log(err) })
            }
        },
    },

    mounted() {
        this.getFiledetails()

    }

}
</script>