<template>
  <q-page class="q-pa-sm layout">
    <div class="page-heading q-pa-sm">{{ this.myStore.treeLabels[0] }}</div>

    <q-card class="q-mt-lg no-shadow flat centerarea2">
      <q-card-section class="q-pa-none">
        <q-card class="flat no-shadow my-card2 centerarea2 q-pa-none">
          <div class="row flex">
            <div
              v-if="isCardVisible && $q.screen.gt.xs"
              :class="{
                ' col-3': LeftTab == 'products',
                '  col-3 ': LeftTab == 'filters',
              }"
            >
              <q-card class="treecard" flat>
                <div
                  class="q-mini-drawer-hide absolute"
                  style="top: 100px; right: -17px; z-index: 99"
                  v-if="isCardVisible == true"
                >
                  <q-btn
                    dense
                    round
                    unelevated
                    color="secondary"
                    icon="fa-solid fa-angles-left"
                    @click="isCardVisible = false"
                  />
                </div>

                <q-tabs
                  class="no-shadow flat"
                  v-model="LeftTab"
                  :switch-indicator="true"
                  align="justify"
                  narrow-indicator
                  active-bg-color="primary text-white"
                  indicator-color="primary"
                >
                  <!-- <q-tab name="products" label="Products" class="curved-tabs" /> -->
                  <q-tab name="filters" label="Customer" class="curved-tabs" />
                </q-tabs>
                <q-separator />
                <q-tab-panels
                  v-model="LeftTab"
                  animated
                  class="text-dark"
                  style="height: 650px"
                >
                  <!-- <q-tab-panel name="products" class="q-pa-none">
                    <bom-tree type="demo" />
                  </q-tab-panel> -->
                  <q-tab-panel name="filters" class="q-pa-none">
                    <RightMenu @dashid="fdashid" :dashid="dashid" type="OEM" />
                  </q-tab-panel>
                </q-tab-panels>
              </q-card>
            </div>
            <div
              :class="{
                'col-9': isCardVisible == true,
                ' col-12': isCardVisible == false || !$q.screen.gt.xs,
              }"
            >
              <div
                class="q-mini-drawer-hide absolute"
                style="top: 100px; left: -5px; z-index: 99"
                v-if="isCardVisible == false"
              >
                <q-btn
                  dense
                  round
                  unelevated
                  color="secondary"
                  icon="fa-solid fa-angles-right"
                  @click="isCardVisible = true"
                />
              </div>

              <SuperSet :dashid="dashid" type="Dashboard" :bom_name="null" />
              <!-- <q-card class="q-ml-md col-12 q-mr-md">
                      <div id="my-superset-container">  Loading.. </div>
                      
                    </q-card> -->
            </div>

            <!-- <div v-if="isCardVisible" :class="{ ' col-3': LeftTab == 'products', '  col-3 ': LeftTab == 'filters' }" >
                <q-card class=" ">
  
                    
  
  
                    
                      
                      
                     
                      
                    
  
                </q-card>   
            </div> -->
          </div>
        </q-card>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { ref } from "vue";

import SuperSet from "@/components/quotessummary/SuperSet.vue";
import RightMenu from "@/components/quotessummary/RightMenu.vue";

// import BomTree from '@/components/BomTree.vue'
//import FilterTab from '@/components/filters/FilterTab.vue'
import { useMyStore } from "@/store";

export default {
  name: "OemPage",

  setup() {
    const isCardVisible = ref(false);
    const closeCard = () => {
      isCardVisible.value = false;
    };
    const myStore = useMyStore();

    return {
      myStore,
      isCardVisible,
      closeCard,
      selected: ref(localStorage.getItem("bom_name")),
      LeftTab: ref("filters"),
      dashid: ref(""),
      //dashidnew: ref(null)
    };
  },
  components: {
    // BomTree,
    // FilterTab,
    RightMenu,
    SuperSet,
  },

  methods: {
    fdashid(dashid) {
      this.dashid = dashid;
    },
  },
  mounted() {
    var moduleData = JSON.parse(localStorage.getItem("DashID"));
    this.dashid = moduleData.Oemid;
  },
};
</script>
