import { fetchWrapper } from "@/helpers";
import { API_BASE_URL } from "@/api.config";

const baseUrl = "/data";
const baseUrl2 = "/api";
const apiUrl = `${API_BASE_URL}`;

export const SettingService = {
  // view Settings json
  ViewSettingData,
  // Settings Data from APi
  SettingsData,
  updateSettingsIpn,
  updateKeyLevelSetting,
  // Icons Json
  Viewicons,
  // Tree Filter json
  ViewFilters,
  // Hierarchy Group By
  hierarchyGroup,
  // H- Custom Filter
  customFilter,
  // Global Filters
  globalFilters,
  // Auto Filters
  AutoFilters,
};

// Setting API
function SettingsData(iteration_name, ipn) {
  if (!ipn) {
    return fetchWrapper.get(
      `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/settings`
    );
  } else {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.get(
      `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/settings/${newipn}`
    );
  }
}

function updateSettingsIpn(iteration_name, body, ipn) {
  if (!ipn) {
    return fetchWrapper.put(
      `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/settings`,
      body
    );
  } else {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.put(
      `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/settings/${newipn}`,
      body
    );
  }
}

function updateKeyLevelSetting(iteration_name, body) {
  return fetchWrapper.put(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/keylevel/settings`,
    body
  );
}

// View Setting API
function ViewSettingData() {
  return fetchWrapper.get(`${baseUrl}/view-settings.json`);
}
//icon Api
function Viewicons() {
  return fetchWrapper.get(`${baseUrl}/icons.json`);
}
// Tree Filter JSON
function ViewFilters() {
  return fetchWrapper.get(`${baseUrl2}/treeFilter.json`);
}
// Hierarchy GroupBy
function hierarchyGroup() {
  return fetchWrapper.get(`${baseUrl2}/HierarchyGroupFields.json`);
}
// Custom Filter
function customFilter() {
  return fetchWrapper.get(`${baseUrl2}/CustomFilter.json`);
}
// Global Settings

function globalFilters() {
  return fetchWrapper.get(`${baseUrl}/Settings.json`);
}
// Aoto Filter Settings

function AutoFilters() {
  return fetchWrapper.get(`${baseUrl2}/AutoFilter.json`);
}
