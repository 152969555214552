<template>
    <q-card class="">
        <div class="">
            <q-card-section style="padding: 5px !important;"
                class="flex card-header-section justify-between items-center bg-card-header"
                :class="{ 'q-pa-none  card-header-section1': errtype == 'errAlert' }">
                <div class="text-h6"><q-btn flat dense icon="error" v-if="errtype == 'errAlert'" color="negative"
                        class=""></q-btn>Error List - {{ fileName }} - ({{ errbomRows.length }} rows)</div>
                <q-btn icon="close" flat dense v-close-popup @click="toggleClose()"> <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]">
               Close
              </q-tooltip></q-btn>
            </q-card-section>

            <q-card-section class="q-pa-md">

                <q-table title="" flat :rows="errbomRows" :columns="errbomFields" row-key="row_number"
                    v-model:pagination="pagination" hide-pagination style="overflow-x: hidden;"
                    :class="{ 'my-sticky-virtscroll-table': errtype != 'errAlert' }">
                    <template v-slot:header="props">
                        <q-tr :props="props" class="text-center">

                            <th>#</th>
                            <q-th v-for="col in props.cols" :key="col.key" class="text-bold">
                                {{ col.label }}
                            </q-th>
                        </q-tr>
                        <!-- :class=" { 'selected-row': props.row.selected }" -->
                    </template>
                    <template v-slot:body="props">

                        <q-tr :props="props">
                            <td style="width:5%" class="text-center">{{ props.rowIndex + 1 }}</td>
                            <q-td key="row_number" class="text-center">
                                {{ props.row.row_number }}

                            </q-td>
                            <q-td key="ipn" class="text-center">{{ props.row.ipn }}</q-td>
                            <q-td key="mpn" class="text-center">{{ props.row.mpn }}</q-td>
                            <q-td key="manufacturer" class="text-center">{{ props.row.manufacturer }}</q-td>
                            <q-td key="required_quantity" class="text-center">{{ props.row.required_quantity }}</q-td>
                            <q-td key="description" class="text-center">{{ props.row.error_description }}</q-td>
                        </q-tr>
                    </template>

                </q-table>
            </q-card-section>
        </div>
        <div class="divider  flex  items-center" v-if="errbomRows.length > 0">

        </div>
        <q-card-section class="flex justify-end items-center  q-pa-none "
            style="padding:5px!important;background-color: white;"
            v-if="errtype != 'errAlert' && errbomRows.length > 0">

            <div class=" cursor-pointer q-mr-sm q-pa-xs" style="font-size: 12px; " dense color="primary"> Records per
                page&nbsp;:&nbsp; <q-btn style="font-size: 12px;" class="btn--no-hover q-pa-none" flat dense
                    :label="pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage"
                    icon-right="fa-solid fa-caret-down" color="primary">
                    <q-menu>
                        <q-list dense v-for="(pag, index) in paginationOptions" :key="index">
                            <q-item clickable v-close-popup @click="selectpagination(pag)" class="q-pa-none">
                                <q-item-section> {{ pag }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>

                </q-btn>
            </div>
            <q-pagination v-model="pagination.page" :max="pagesNumber" input class="paginationclass q-mr-sm" size="sm"
                direction-links boundary-links icon-first="skip_previous" icon-last="skip_next" icon-prev="fast_rewind"
                icon-next="fast_forward" />
        </q-card-section>

    </q-card>
</template>


<script>
import { ref } from 'vue'

export default {
    props: {
        errbomRows: {
            type: Array
        },
        errbomFields: {
            type: Array
        },
        fileName: {
            type: String
        },
        errtype: {
            type: String
        },
    },
    setup() {
        const pagination = ref({
            sortBy: 'desc',
            descending: false,
            page: 1,
            rowsPerPage: 5
            // rowsNumber: xx if getting data from a server
        })
        const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, 'All']);
        return {
            pagination,
            paginationOptions,
            // pagesNumber: computed(() => Math.ceil(props.errbomRows.length / pagination.value.rowsPerPage)),


        }
    },
    computed:{
        pagesNumber(){
        if (this.pagination.rowsPerPage != -1 ) {
                const newpage = Math.ceil(this.errbomRows.length / this.pagination.rowsPerPage)
                return newpage
            } 
            else { return 1 }
        }
    },
    methods: {
        toggleClose() {
            this.$emit('toggleClose', true)
        },
        selectpagination(value) {   //Changing the Pagination variable
            if (value == 'All') {
                this.pagination.rowsPerPage = -1
            } else {
                this.pagination.rowsPerPage = parseInt(value);
            }
            this.pagination.page = 1
        },
    },


}
</script>
<style lang="sass" scoped>
.card-action-inner .q-card__section--vert
    padding: 0px !important  //eslint-disable-next-line
.row
    flex-wrap: nowrap !important
.my-sticky-virtscroll-table
  /* height or max-height is important */
  height: 200px
  width:1000px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    // background-color: #00b4ff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>