import { fetchWrapper } from "@/helpers";
import { API_BASE_URL } from "@/api.config";
import axios from "axios";

const apiUrl2 = `${API_BASE_URL}`;

export const AlterPartService = {
  getAlterparts,
  addAslparts,
  getalterSubParts,
  exportParts,
  alterPriceBreak,
  // Part Library
  getpartlibrary,
  autocomplete,
  partlibrarysearch,
  getASlList,
  getAlternatePartsList,
  // Watch List
  getwatchlist,
  removewatchlist,
  createtagname,
  addwatchlist,
  watchlistSettings,
  deletepartlibrary,
  updatepartlifecycle,
  getAssociatedBoms,
  searchWatchlist,
  getSupersetGuest,
};

// part alternatives
function getAlterparts(bom_name, ipn, source_type, is_exact, part_status) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  return fetchWrapper.get(
    `${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}?source_type=${source_type}&is_exact=${is_exact}&part_status=${part_status}`
  );
}
function addAslparts(bom_name, ipn, body) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  return fetchWrapper.post(
    `${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}`,
    body
  );
}

// Alternate parts price break
function alterPriceBreak(supplier_name, spn) {
  // let newspn = encodeURIComponent(encodeURIComponent(spn))
  return fetchWrapper.get(
    `${apiUrl2}/sourcing/alternative/parts/pricebreak/${supplier_name}/${spn}`
  );
}
// Alternate parts Options
function getalterSubParts(mpn, manufacturer) {
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.get(
    `${apiUrl2}/sourcing/alternative-parts/${newmpn}/${newmanufacturer}`
  );
}
// export alternative parts
async function exportParts(bom_name, ipn) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  const cmuser2 = JSON.parse(localStorage.getItem("cmuser")) || {
    access_token: null,
  };
  try {
    const response = await axios.get(
      `${apiUrl2}/sourcing/alternative/parts/${bom_name}/${newipn}/export`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          "X-API-Key": cmuser2.access_token,
          "Content-Type": "application/json",
          responseType: "arraybuffer",
        },
      }
    );

    // Create a Blob from the array buffer
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Part Alternatives - ${ipn}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
}

// Parts Library services
// Get Part Library
function getpartlibrary(search_input) {
  return fetchWrapper.post(
    `${apiUrl2}/part/library?search_input=${search_input}`
  );
}
// search auto complete
function autocomplete(search_input) {
  return fetchWrapper.post(
    `${apiUrl2}/part/auto/complete?search_input=${search_input}`
  );
}

// search watchlist
function searchWatchlist(search_input) {
  return fetchWrapper.post(
    `${apiUrl2}/watchlist/search?search_input=${search_input}`
  );
}

// get part library with auto suggestion
function partlibrarysearch(search_input, input_type) {
  return fetchWrapper.post(
    `${apiUrl2}/part/library/v1?search_input=${search_input}&input_type=${input_type}`
  );
}
// Get watch list
function getwatchlist() {
  return fetchWrapper.get(`${apiUrl2}/watchlist/list/all`);
}
// Remove from watch list
function removewatchlist(ipn, mpn, manufacturer, tag_name) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.post(
    `${apiUrl2}/watchlist/remove/${newipn}/${newmpn}/${newmanufacturer}/${tag_name}`
  );
}
// Create watch list tag name
function createtagname(body) {
  return fetchWrapper.post(`${apiUrl2}/watchlist/create/tag`, body);
}
// add to  watch list
function addwatchlist(ipn, mpn, manufacturer, tag_name, body) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.post(
    `${apiUrl2}/watchlist/add/${newipn}/${newmpn}/${newmanufacturer}/${tag_name}`,
    body
  );
}

//fetch watchlist settings
function watchlistSettings(ipn, mpn, manufacturer, tag_name) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.get(
    `${apiUrl2}/watchlist/get/${newipn}/${newmpn}/${newmanufacturer}/${tag_name}`
  );
}

// delete parts from partlibrary
function deletepartlibrary(ipn, mpn, manufacturer) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.delete(
    `${apiUrl2}/part/${newipn}/${newmpn}/${newmanufacturer}`
  );
}
// update parts life cycle status
function updatepartlifecycle(ipn, mpn, manufacturer, status) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.put(
    `${apiUrl2}/part/change/${newipn}/${newmpn}/${newmanufacturer}/status/${status}`
  );
}

//get alternate supplier list
function getASlList(ipn) {
  return fetchWrapper.get(`${apiUrl2}/part/asl/parts/${ipn}`);
}

//get alternate parts list
function getAlternatePartsList(ipn, is_avl) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  return fetchWrapper.get(
    `${apiUrl2}/part/alternative/parts/${newipn}?is_avl=${is_avl}`
  );
}

function getAssociatedBoms(ipn, mpn, manufacturer) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  let newmanufacturer = encodeURIComponent(encodeURIComponent(manufacturer));
  return fetchWrapper.get(
    `${apiUrl2}/part/associated/bom/${newipn}/${newmpn}/${newmanufacturer}`
  );
}

async function getSupersetGuest(id, mpn) {
  var tok = await fetchWrapper.post(
    `${apiUrl2}/user/dashboard/${id}/mpn/${mpn}`
  );
  return tok;
}
