import '@/styles/custom/styles.scss'
import '@/styles/custom/table.sass'
import '@/styles/custom/content-div.scss'
import '@/styles/custom/content-div-up.scss'
import '@/styles/custom/new.scss'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';

// To be used on app.use(Quasar, { ... }

export default {
  config: {"notify":{}},
  plugins: {Notify,Dialog,Loading,AppFullscreen} 
}