import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue'
import { useMyStore } from './store'
import { router } from './helpers';
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import moment from 'moment'
import { VueEmailPlugin } from 'vue-email'

const app = createApp(App).use(Quasar, quasarUserOptions);
const pinia = createPinia();
app.config.globalProperties.$filters = {
  formatDate(value) {

    return moment.utc(value).local().format("YYYY-MM-DD")
  },
  ToasterDate(value) {
    if (value === null) {
      return '-'
    }
    else {
      return moment.utc(value).local().format('DD-MMM');
    }
  },
  formatFirstLetter(index) {
    return index.charAt(0).toUpperCase() + index.slice(1).replace(/([A-Z])/g, ' $1')

  },
  QuotesToaster(date2) {
    var date = date2 || null
    if (date === null || date == " - ") {
      return "-"
    }
    else if (date !== null && date !== undefined) {
      return moment(date).utc().utcOffset("+05:30").format("DD-MMM")
    }
    else {
      return " - "
    }

  },
  rfqDuedate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).utc().utcOffset("+05:30").format("YYYY-MM-DD")
    }
    else {
      return " - "
    }

  },
  overDueDateFormat(dueDate2, currentDate) {
    var dueDate = dueDate2 || null
    if (dueDate == null || currentDate == null) {
      return "-"
    } else {
      var starttime = moment(currentDate).utcOffset("+05:30")
      var endtime = moment(dueDate).utcOffset("+05:30")
      var duration = moment.duration(endtime.diff(starttime));
      var days = duration.days();
      var hours = duration.hours();
      if (hours > 0 && Math.abs(endtime.diff(starttime, "days")) !== 0) {
        return Math.abs(endtime.diff(starttime, "days")) + 1
      }

      if (Math.abs(endtime.diff(starttime, "days"))) {
        return Math.abs(endtime.diff(starttime, "days"));
      } else if (hours > 0 && Math.abs(days) == 0) {
        return 1
      }
      else {
        return '-'
      }
    }
  },

  overDueDate(dueDate, currentDate) {
    // let rdate = ''
    if (dueDate == null || currentDate == null) {
      return "- "
    }
    else {
      // var due = moment(dueDate).utc().utcOffset("+05:30")
      // var curr = moment(currentDate).utc().utcOffset("+05:30")
      var starttime = moment(currentDate).utcOffset("+05:30")
      var endtime = moment(dueDate).utcOffset("+05:30")
      var duration = moment.duration(endtime.diff(starttime));
      var days = duration.days();
      var hours = duration.hours();

      if (hours > 0 && Math.abs(endtime.diff(starttime, "days")) !== 0) {
        return  Math.abs(endtime.diff(starttime, "days")) + 1
      }
      // else if (hours > 0 && due.diff(curr, "days") === 0) {
      //   console.log("rdate", rdate)
      //   return rdate = 1
      // } else if (hours === 0 && due.diff(curr, "days") === 0) {
      //   return '-'
      // }
      else if (Math.abs(endtime.diff(starttime, "days"))) {
        return endtime.diff(starttime, "days");
      } else if (hours > 0 && Math.abs(days) == 0) {
        return 1
      }
      else {
        return '-'
      }


    }
  }

}
app.use(VueEmailPlugin)
app.use(createPinia());
app.use(router);
app.use(pinia);
app.mount('#app');
app.component('MyComponent', {
  setup() {
    const myStore = useMyStore();
    return { myStore };
  },
});

export { pinia };