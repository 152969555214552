<template>
  <q-form @submit="onSubmit(oem, product, iteration)" class="q-gutter-md">
    <q-card class="oempopup">
      <q-card-section class="gdialog q-pa-none">
        <div class="row col-12">
          <div class="col-5 gdialog-left">
            <q-card>
              <q-card-actions
                flat
                class="bg-primary text-white"
                style="border-radius: 0px"
              >
                <span align="left" class="q-py-xs">Document Explorer </span>
                <q-space />
              </q-card-actions>
              <q-card-section class="q-pa-none">
                <q-scroll-area style="height: 500px">
                  <SpinnerComponent type="Tree" v-if="loader" />
                  <PopupTree type="popup" v-if="!loader" />

                  <!-- <q-tree :nodes="treeData" node-key="name" accordion label-key="name"></q-tree> -->
                </q-scroll-area>
              </q-card-section>
            </q-card>
          </div>

          <div class="col-7">
            <q-card>
              <q-card-actions flat class="q-pb-none" style="border-radius: 0px">
                <span
                  align="right"
                  class="explr-title q-pa-sm"
                  v-if="popupType !== 'edit'"
                >
                  <span v-if="Type == 'oem'"
                    >New {{ this.myStore.treeLabels[0] }}</span
                  >
                  <span v-if="Type == 'product'"
                    >New {{ this.myStore.treeLabels[1] }}</span
                  >
                  <span v-if="Type == 'iteration'"
                    >New
                    {{
                      this.myStore.userdata.role == "quoter"
                        ? `${this.myStore.treeLabels[2]}`
                        : `${this.myStore.treeLabels[3]}`
                    }}</span
                  >
                  <!-- New {{Type == 'oem' ? Type.toUpperCase() : Type.charAt(0).toUpperCase() + Type.slice(1) }} -->

                  <!-- OEM  props: {{ popOEm }}  Product Props:{{ popProduct }}  Iter:{{ popIter }} -->
                </span>
                <span align="center" class="explr-title q-pa-sm" v-else>
                  <!-- Edit {{ node == 'oem' ? node.toUpperCase(): node.charAt(0).toUpperCase() + node.slice(1) }} -->
                  <span v-if="node == 'oem'"
                    >Edit {{ this.myStore.treeLabels[0] }}</span
                  >
                  <span v-if="node == 'product'"
                    >Edit {{ this.myStore.treeLabels[1] }}</span
                  >
                  <span v-if="node == 'iteration'"
                    >Edit
                    {{
                      myStore.partListStatus == false
                        ? this.myStore.treeLabels[2]
                        : this.myStore.treeLabels[3]
                    }}</span
                  >
                </span>
              </q-card-actions>
              <q-card-section v-if="loader">
                <SpinnerComponent type="PopupFields" v-if="loader" />
              </q-card-section>
              <q-card-section v-if="!loader">
                <div
                  v-if="Type === 'oem' || node === 'oem'"
                  class="col-12 q-gutter-md"
                >
                  <!-- v-model="oem.customer_name" -->
                  <q-input
                    class="q-pb-none"
                    v-model="myStore.NOEM"
                    dense
                    filled
                    :rules="textRules"
                    :label="`${this.myStore.treeLabels[0]}`"
                    placeholder="Untitled"
                  />
                  <q-input
                    v-model="oem.description"
                    filled
                    dense
                    class="q-mt-lg q-mb-sm oemdesc1"
                    label="Description"
                  />
                  <json-forms
                    :data="jformData"
                    :schema="schema"
                    :uischema="uischema"
                    :renderers="renderers"
                    @change="onChange"
                  />
                </div>
                <div
                  v-else-if="Type === 'product' || node === 'product'"
                  class="col-12 q-gutter-md"
                >
                  <!--  v-model="oem.customer_name" -->
                  <q-select
                    v-if="popupType !== 'edit'"
                    :label="`Select  ${this.myStore.treeLabels[0]}`"
                    :dense="dense"
                    v-model="myStore.NOEM"
                    :rules="selectOEM"
                    filled
                    outlined
                    :options="oemOptions"
                    emit-value
                    map-options
                    class="q-pb-none"
                    @update:model-value="OnSelectChange(myStore.NOEM)"
                  >
                    <template v-slot:option="{ itemProps, opt }">
                      <q-item v-bind="itemProps">
                        <q-item-section>
                          {{ opt }}
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No Options
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                  <!-- v-model="product.product_name" -->
                  <q-input
                    class="q-pb-none q-mt-lg"
                    dense
                    filled
                    v-model="myStore.NProduct.product"
                    :rules="RFQRules"
                    :label="`${this.myStore.treeLabels[1]}`"
                    placeholder="Untitled"
                  />

                  <q-input
                    v-model="product.description"
                    filled
                    dense
                    class="q-mt-lg q-mb-sm oemdesc1"
                    label="Description"
                  />
                  <json-forms
                    :data="jformData"
                    :schema="schema"
                    :uischema="uischema"
                    :renderers="renderers"
                    @change="onChange"
                  />
                </div>
                <div v-else class="col-12 q-gutter-md">
                  <!-- v-model="oem.customer_name" -->
                  <q-select
                    v-if="popupType !== 'edit'"
                    class="q-mt-xs q-pb-none err-message"
                    :dense="dense"
                    v-model="myStore.NOEM"
                    filled
                    outlined
                    :options="oemOptions"
                    emit-value
                    map-options
                    :label="`Select ${this.myStore.treeLabels[0]}`"
                    :rules="selectOEM"
                    @update:model-value="OnSelectChangeOEM(myStore.NOEM)"
                  >
                    <template v-slot:option="{ itemProps, opt }">
                      <q-item v-bind="itemProps">
                        <q-item-section>
                          {{ opt }}
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No Options
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                  <!-- v-model="product.product_name"  if-oem.customer_name  -->
                  <q-select
                    v-if="popupType !== 'edit' && myStore.NOEM"
                    :dense="dense"
                    v-model="myStore.NProduct.product"
                    class="q-pb-none q-mt-md err-message"
                    filled
                    :options="productOptions"
                    emit-value
                    map-options
                    :rules="selectProduct"
                    :label="`Select  ${this.myStore.treeLabels[1]}`"
                    @update:model-value="
                      OnSelectChangeProduct(myStore.NProduct.product)
                    "
                  >
                    <template v-slot:option="{ itemProps, opt }">
                      <q-item v-bind="itemProps">
                        <q-item-section>
                          {{ opt }}
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No Options
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                  <!-- v-model="iteration.iteration_name" -->
                  <q-input
                    class="q-pb-none q-mt-md err-message"
                    dense
                    filled
                    :rules="RFQRules"
                    v-model="myStore.NIter.iteration"
                    :disable="popupType == 'edit'"
                    :label="
                      this.myStore.userdata.role == 'quoter'
                        ? `${this.myStore.treeLabels[2]}`
                        : `${this.myStore.treeLabels[3]}`
                    "
                    placeholder="Untitled"
                  />

                  <q-input
                    v-model="iteration.description"
                    filled
                    dense
                    class="q-mt-md oemdesc1"
                    label="Description"
                  />

                  <json-forms
                    :data="iterationjformData"
                    :schema="iterationSchema"
                    :uischema="iterationUischema"
                    :renderers="renderers"
                    @change="iterationChange"
                  />
                  <q-input
                    v-if="myStore.userdata.role == 'buyer'"
                    filled
                    v-model="dueDate"
                    dense
                    label="Due Date"
                    readonly
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-banner class="q-pa-none">
                            <q-date v-model="dueDate" mask="YYYY-MM-DD">
                              <div class="row items-center justify-end">
                                <q-btn
                                  v-close-popup
                                  label="Close"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-banner>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </q-card-section>

              <q-card-actions align="right" class="q-pr-md">
                <q-btn
                  :loading="loading"
                  label="Save"
                  outline
                  class="quote-btn"
                  color="secondary"
                  type="submit"
                >
                  <template v-slot:loading>
                    <q-spinner-facebook />
                  </template>
                </q-btn>
                <!-- <q-btn v-else label="Save" type="submit" color="secondary text-black" size="md" /> -->
                <q-btn
                  label="Cancel"
                  v-close-popup
                  outline
                  class="quote-btn"
                  color="secondary"
                  @click="closepopup"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-form>
</template>

<script>
import { JsonForms } from "@jsonforms/vue";
import { defineAsyncComponent } from "vue";
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from "@jsonforms/vue-vanilla";
import { defineComponent, ref } from "vue";
// import BomTree from './BomTree.vue';
import { useMyStore } from "@/store";
import SpinnerComponent from "@/utils/SpinnerComponent.vue";
import { AlertService, UploadService } from "@/services";
import moment from "moment";
const myStyles = mergeStyles(defaultStyles, {
  control: { root: "my-control my-control2" },
  classNames: "text-fields-custom",
});
export default defineComponent({
  name: "app",
  components: {
    JsonForms,
    // BomTree,
    PopupTree: defineAsyncComponent(() => import("./PopupTree.vue")),
    SpinnerComponent,
  },
  // props: ["Type", "popupType", "editNode", "node", "close", "addClose","popOEm","popProduct","popIter"],
  props: {
    Type: { type: String },
    popupType: { type: String },
    editNode: { type: String },
    node: { type: String },
    close: { type: String },
    newClose: { type: String },
    pageType: { type: String },
    addClose: { type: String },
    popOEm: { type: Object },
    popProduct: { type: Object },
    popIter: { type: Object },
  },
  setup() {
    const myStore = useMyStore();
    const textRules = [
      (v) => !!v || "Field is required",
      (v) =>
        (v && v.length >= 3 && v.length <= 20) ||
        "Must be between 3 and 20 characters",
      (v) => /^[^\s]+$/.test(v) || "No spaces allowed",
      (v) => /^[a-zA-Z0-9_]+$/.test(v) || "No special characters allowed",
    ];
    const RFQRules = [
      (v) => !!v || "Field is required",
      (v) =>
        (v && v.length >= 3 && v.length <= 20) ||
        "Must be between 3 and 20 characters",
      (v) => /^[^\s]+$/.test(v) || "No spaces allowed",
      (v) => /^[a-zA-Z0-9_-]+$/.test(v) || "No special characters allowed",
    ];
    const selectOEM = [(val) => !!val || `Select ${myStore.treeLabels[0]}`];
    const selectProduct = [(val) => !!val || `Select ${myStore.treeLabels[1]}`];
    const oem = ref({ customer_name: "", description: "" });

    return {
      myStore,
      loading: ref(false),
      textRules,
      RFQRules,
      selectProduct,
      selectOEM,
      treeData: ref([]),
      loader: ref(true),
      selectedOEM: ref(""),
      selectedProduct: ref(""),
      selectedIteration: ref(""),
      dueDate: ref(""),
      renderers: Object.freeze(vanillaRenderers),
      jformData: {
        name: "",
        mail: "",
        location: "",
      },
      dense: ref(true),
      iterationjformData: {
        name: "",
        mail: "",
        location: "",
        // lead_time: 0,
        is_primary: true,
        details: "",
      },
      schema: ref(null),
      uischema: ref(null),
      iterationUischema: ref(null),
      iterationSchema: ref(null),
      oem,
      product: ref({ product_name: "", description: "" }),
      iteration: ref({ iteration_name: "", description: "" }),
    };
  },
  watch: {
    "myStore.NOEM": function (newOem) {
      const oemExpand = JSON.parse(localStorage.getItem("expandedNodes"));
      if (oemExpand != null && newOem !== oemExpand[0]) {
        this.myStore.NProduct.product = "";
        this.myStore.NIter.iteration = "";
      } else {
        this.myStore.NOEM = newOem;
      }
    },
    "myStore.NProduct.product": function (newProduct) {
      const productExpand = JSON.parse(localStorage.getItem("expandedNodes"));
      if (productExpand != null && newProduct !== productExpand[1]) {
        this.myStore.NIter.iteration = "";
      } else {
        this.myStore.NProduct.product = newProduct;
      }
    },
  },

  computed: {
    oemOptions() {
      return [...new Set(this.treeData.map((item) => item.name))];
    },
    productOptions() {
      // this.oem.customer_name
      const products = this.treeData
        .filter((item) => item.name === this.myStore.NOEM)
        .flatMap((item) => item.children.map((child) => child.name));
      console.log("'All', ...new Set(products)", products);
      return [...new Set(products)];
    },
  },
  methods: {
    OnSelectChangeOEM(val) {
      if (val) {
        this.myStore.NProduct.product = "";
        this.myStore.NIter.iteration = "";
      }
    },
    OnSelectChangeProduct(val) {
      if (val) {
        this.myStore.NIter.iteration = "";
      }
    },
    onChange(event) {
      this.jformData = event.data;
    },
    iterationChange(event) {
      this.iterationjformData = event.data;
    },
    getTree() {
      this.loader = true;
      UploadService.getStaticTree()
        .then((x) => {
          this.treeData = x;
          // setTimeout(() => {
          this.loader = false;
          // }, 500)

          console.log("treeData", this.treeData);
        })
        .catch((err) => {
          this.loader = false;
          console.log(err);
        });
    },
    closepopup() {
      const oemExpand = JSON.parse(localStorage.getItem("expandedNodes"));
      this.myStore.NOEM = oemExpand[0];
      this.myStore.NProduct.product = oemExpand[1];
      this.myStore.NIter.iteration = this.myStore.bom_name;
    },
    onSubmit(oem, product, iteration) {
      this.myStore.isCardVisible = true;
      this.myStore.isToastVisible = true;
      if (this.popupType !== "edit") {
        return this.create(oem, product, iteration);
      } else {
        return this.update(oem, product, iteration);
      }
    },
    create(oem, product, iteration) {
      if (this.Type === "oem") {
        this.loading = true;
        const body = {
          customer_name: this.myStore.NOEM,
          description: oem.description,
          more_info: {
            additionalProp1: this.jformData.name,
            additionalProp2: this.jformData.mail,
            additionalProp3: this.jformData.location,
          },
        };
        UploadService.addCustomer(body)
          .then((x) => {
            setTimeout(() => {
              // this.$emit('created', this.myStore.NOEM)
              AlertService.Success(x);
              this.addClose();
              this.myStore.refreshpricing = Math.random();
              this.loading = false;
            }, "3000");
          })
          .catch((err) => {
            this.loading = false;
            AlertService.Error(err);
            //this.addClose();
          });
      } else if (this.Type === "product") {
        this.loading = true;
        if (this.myStore.NOEM === this.myStore.NProduct.product) {
          AlertService.Error("Customer and Assembly Name is Same");
          this.loading = false;
        } else {
          const body = {
            product_name: this.myStore.NProduct.product,
            description: product.description,
            more_info: {
              additionalProp1: this.jformData.name,
              additionalProp2: this.jformData.mail,
              additionalProp3: this.jformData.location,
            },
          };
          UploadService.addProduct(this.myStore.NOEM, body)
            .then((x) => {
              // this.$emit('created', this.myStore.NProduct.product)
              AlertService.Success(x);
              this.addClose();
              this.myStore.refreshpricing = Math.random();
              this.loading = false;
            })
            .catch((err) => {
              AlertService.Error(err);
              //this.addClose();
              this.loading = false;
            });
          console.log("SubmitData", body);
        }
      } else {
        this.loading = true;
        if (
          this.myStore.NOEM === this.myStore.NIter.iteration ||
          this.myStore.NProduct.product === this.myStore.NIter.iteration
        ) {
          AlertService.Error("RFQ Name must be Unique");
          this.loading = false;
        } else {
          var email = this.iterationjformData.mail || null;
          if (email == "") {
            email = null;
          }
          var date = "";
          if (this.dueDate != null && this.dueDate != "") {
            date = new Date(this.dueDate).toISOString();
          } else {
            date = null;
          }
          const body = {
            iteration_name: this.myStore.NIter.iteration,
            description: iteration.description,
            due_date: date,
            more_info: {
              name: this.iterationjformData.name,
              email: email,
              location: this.iterationjformData.location,
            },
          };
          UploadService.addIteration(
            this.myStore.NOEM,
            this.myStore.NProduct.product,
            body
          )
            .then((x) => {
              setTimeout(() => {
                console.log("Delayed for 3 second.");
                // this.$emit('created', this.myStore.NIter.iteration)
                AlertService.Success(x);
                var r = [];
                r.push(this.myStore.NOEM),
                  r.push(this.myStore.NProduct.product);
                localStorage.setItem("expandedNodes", JSON.stringify(r));
                localStorage.setItem("bom_name", this.myStore.NIter.iteration);
                this.myStore.loadQuotes();
                if (this.pageType === "Quotes") {
                  this.newClose();
                } else {
                  this.addClose();
                }
                this.myStore.refreshpricing = Math.random();
                this.loading = false;
              }, "3000");
            })
            .catch((err) => {
              AlertService.Error(err);
              //this.addClose();
              this.loading = false;
            });

          console.log("SubmitData", body);
        }
      }
    },

    update(oem, product, iteration) {
      console.log("editNode", this.editNode);

      if (this.node === "oem") {
        this.loading = true;
        const body = {
          customer_name: this.myStore.NOEM,
          description: oem.description,
          more_info: {
            additionalProp1: this.jformData.name,
            additionalProp2: this.jformData.mail,
            additionalProp3: this.jformData.location,
          },
        };
        UploadService.updateCustomer(this.editNode, body)
          .then((x) => {
            setTimeout(() => {
              // this.$emit('updated', this.myStore.NOEM)
              AlertService.Success(x);
              this.close();
              this.myStore.refreshpricing = Math.random();
              this.loading = false;
            }, "3000");
          })
          .catch((err) => {
            AlertService.Error(err);
            //this.addClose();
            this.loading = false;
          });
        console.log("SubmitData", body);
      } else if (this.node === "product") {
        this.loading = true;
        const body = {
          product_name: this.myStore.NProduct.product,
          description: product.description,
          more_info: {
            additionalProp1: this.jformData.name,
            additionalProp2: this.jformData.mail,
            additionalProp3: this.jformData.location,
          },
        };
        UploadService.updateProduct(this.editNode, body)
          .then((x) => {
            // this.$emit('updated', this.myStore.NProduct.product)
            AlertService.Success(x);
            this.close();
            this.myStore.refreshpricing = Math.random();
            this.loading = false;
          })
          .catch((err) => {
            AlertService.Error(err);
            //this.addClose();
            this.loading = false;
          });
        console.log("SubmitData", body);
      } else {
        this.loading = true;
        var date = "";
        if (this.dueDate != null && this.dueDate != "") {
          date = new Date(this.dueDate).toISOString();
        } else {
          date = null;
        }
        const body = {
          iteration_name: this.myStore.NIter.iteration,
          description: iteration.description,
          is_part_list: iteration.is_part_list,
          due_date: date,
          more_info: {
            name: this.iterationjformData.name,
            email: this.iterationjformData.mail || null,
            location: this.iterationjformData.location,
          },
        };
        UploadService.updateIteration(this.editNode, body)
          .then((x) => {
            setTimeout(() => {
              // this.$emit('updated', this.myStore.NIter.iteration)
              AlertService.Success(x);
              this.close();
              this.myStore.refreshpricing = Math.random();
              this.loading = false;
            }, "3000");
          })
          .catch((err) => {
            AlertService.Error(err);
            //this.addClose();
            this.loading = false;
          });
        console.log("SubmitData", body);
      }
    },
    async getByIdData() {
      if (this.popupType === "edit") {
        if (this.node === "oem") {
          await UploadService.getById(this.editNode)
            .then((x) => {
              this.oem = x;
              this.myStore.NOEM = x.customer_name;
              if (x.more_info != null && x.more_info != undefined) {
                this.jformData = {
                  name: x.more_info.additionalProp1,
                  mail: x.more_info.additionalProp2,
                  location: x.more_info.additionalProp3,
                };
              } else {
                this.jformData = {
                  name: "",
                  mail: "",
                  location: "",
                };
              }

              console.log("popup oem ", this.oem);
            })
            .catch((err) => console.log(err));
        } else if (this.node === "product") {
          await UploadService.getByProductID(this.editNode)
            .then((x) => {
              console.log("this.more_info", x);
              this.product = x;
              this.myStore.NProduct.product = x.product_name;
              this.jformData = {
                name: x.more_info.additionalProp1,
                mail: x.more_info.additionalProp2,
                location: x.more_info.additionalProp3,
              };
            })
            .catch((err) => console.log(err));
        } else {
          await UploadService.getIterationByID(this.editNode)
            .then((x) => {
              this.iteration = x;
              this.myStore.NIter.iteration = x.iteration_name;
              if (
                x.due_date != null &&
                x.due_date != undefined &&
                x.due_date != ""
              ) {
                this.dueDate = moment(x.due_date)
                  .utc()
                  .utcOffset("+05:30")
                  .format("YYYY-MM-DD");
              } else {
                this.dueDate = "";
              }
              this.iterationjformData = {
                // lead_time: x.lead_time,
                name: x.more_info.name,
                mail: x.more_info.email,
                location: x.more_info.location,
                // details: x.required_quantity['CopyOfRfq'].details,
                // is_primary: x.required_quantity['CopyOfRfq'].is_primary
              };
            })
            .catch((err) => console.log(err));
        }
      }
    },
  },
  provide() {
    return {
      styles: myStyles,
    };
  },
  created() {
    this.getTree();
  },
  mounted() {
    if (this.myStore.bom_name != null) {
      const PopupNodes = JSON.parse(localStorage.getItem("expandedNodes"));
      if (this.Type == "oem") {
        this.myStore.NOEM = "";
      }
      if (this.Type == "product") {
        this.myStore.NOEM = PopupNodes[0];
        this.myStore.NProduct.product = "";
      }
      if (this.Type == "iteration") {
        this.myStore.NOEM = PopupNodes[0];
        this.myStore.NProduct.product = PopupNodes[1];
        this.myStore.NIter.iteration = "";
      }
    }
    this.getTree();
    this.getByIdData();
    UploadService.getSchema().then((x) => {
      this.schema = x;
      this.loader = false;
    });
    UploadService.getUiSchema().then((x) => {
      this.uischema = x;
      this.loader = false;
    });
    UploadService.getiterationUiSchema().then((x) => {
      this.iterationUischema = x;
      this.loader = false;
    });
    UploadService.getiterationSchema().then((x) => {
      this.iterationSchema = x;
      this.loader = false;
    });
  },
});
</script>

<style scoped lang="scss">
.quote-btn {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.explr-title {
  font-size: 16px;
  font-weight: 600;
  color: #3d4050;
}
</style>
