<template>
  <q-dialog
    v-model="dialog"
    class="part-alternates"
    persistent
    @keyup.esc="dialog = false"
    @hide="clearDialogData"
  >
    <q-card
      class="q-pa-md"
      style="
        min-width: 80vw;
        min-height: 70vh;
        background-color: rgba(255, 255, 255, 0.95);
      "
    >
      <header class="part-detail-title">
        <h6>
          Notifications
          <span v-if="flag === true"> - MPN : {{ queryParams.mpn }}</span>
          <span v-if="flag === false">
            - BOM : {{ queryParams.bom_name }} & IPN :
            {{ queryParams.ipn }}</span
          >
        </h6>

        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </header>
      <q-card
        flat
        ref="fullscreenTarget"
        :class="[
          'card-box',
          'q-pa-none',
          fullscreenMode ? 'fullscreen-active' : '',
        ]"
        bordered
      >
        <q-card-actions class="notifyhead">
          <aside class="action-flex">
            <q-btn
              flat
              dense
              round
              :icon="toggleFullscreenicon"
              @click="toggleFullscreen1"
              class="round-icon-btn"
            >
              <q-tooltip
                anchor="bottom middle"
                self="top middle"
                :offset="[-2, -2]"
                >{{
                  toggleFullscreenTip
                    .replace("to", "")
                    .replace("enter", "Enter")
                    .replace("exit", "Exit")
                }}</q-tooltip
              >
            </q-btn>
            <q-input
              v-if="isSearchOpen"
              v-model="search"
              label="Search"
              outlined
              dense
              class="notifysearch"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  dense
                  color="primary"
                  size="sm"
                  icon="clear"
                  @click="closeSearch"
                />
              </template>
            </q-input>
            <q-btn
              v-if="!isSearchOpen"
              round
              dense
              flat
              icon="search"
              @click="toggleSearch"
              class="round-icon-btn"
            >
              <q-tooltip
                anchor="bottom middle"
                self="top middle"
                :offset="[-2, -2]"
                >Search</q-tooltip
              ></q-btn
            >
            <q-btn
              round
              dense
              flat
              class="round-icon-btn"
              icon="refresh"
              @click="openconfirm('reset')"
              size="15px"
            >
              <q-tooltip
                anchor="bottom middle"
                self="top middle"
                :offset="[-2, -2]"
                >Refresh</q-tooltip
              ></q-btn
            >
          </aside>
          <aside class="action-flex">
            <!-- <div class="q-ml-lg">Unread({{ myStore.unreadcount }})</div> -->
            <q-btn
              dense
              round
              flat
              icon="mark_email_unread"
              class="unread-icon-btn"
            >
              <q-badge color="red" floating transparent>
                {{ unreadCount }}
              </q-badge>
              <q-tooltip
                anchor="bottom left"
                self="top middle"
                :offset="[-2, -2]"
                >Unread messages</q-tooltip
              >
            </q-btn>

            <q-btn
              href="#"
              v-if="!isSearchOpen"
              flat
              dense
              round
              icon="drafts"
              class="read-icon-btn"
              @click="openconfirm('mark')"
              :disable="unreadNotification.length == 0"
            >
              <q-tooltip
                anchor="bottom left"
                self="top middle"
                :offset="[-2, -2]"
                >Mark all as read</q-tooltip
              >
            </q-btn>
          </aside>
        </q-card-actions>
        <q-card-section class="q-pa-none">
          <SpinnerComponent type="Table" v-if="loader"></SpinnerComponent>
          <q-markup-table
            v-if="!loader"
            :class="toggleClassFullscreen"
            class="notifyTable"
          >
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(col, index) in Notifyheader"
                  :key="index"
                >
                  {{ col.label }}
                  <q-icon
                    v-if="col.sortable"
                    :name="
                      col.sortDirect == null || col.sortDirect == 'asc'
                        ? 'arrow_upward'
                        : 'arrow_downward'
                    "
                    @click="customSort(col)"
                  ></q-icon>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(all, index) in data"
                :key="index"
                :class="all.is_read ? 'text-grey' : 'text-black'"
              >
                <td class="text-left">
                  <q-checkbox
                    :disable="all.is_read"
                    dense
                    size="xs"
                    :model-value="all.is_read"
                    @update:model-value="updateRead(all, index)"
                    color="positive"
                  >
                    <q-tooltip
                      anchor="bottom middle"
                      self="top middle"
                      :offset="[-2, -2]"
                      >{{
                        all.is_read
                          ? "Already marked as read"
                          : "Click to Mark as read"
                      }}
                    </q-tooltip>
                  </q-checkbox>
                </td>
                <td class="text-left">
                  <span v-if="all.more_info.bom_name != null">
                    <a
                      class="notify-bom"
                      href="#"
                      @click="updateRead(all, index)"
                      >{{ all.more_info.bom_name }}</a
                    >
                  </span>

                  <span v-else>- </span>
                </td>
                <td class="text-left">
                  <span v-if="all.more_info.ipn != null">
                    <p class="notify-ipn">
                      {{ all.more_info.ipn != null ? all.more_info.ipn : "-" }}
                    </p>
                  </span>
                  <span v-else>- </span>
                </td>
                <td class="text-left">{{ formatDate(all.created_at) }}</td>
                <td class="text-left" style="width: 800px">
                  <div class="message-wrapper">
                    <span>{{ all.message }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
          <div v-if="data.length === 0 && !loader">
            <div class="full-width row flex-center text-black q-gutter-sm">
              <q-icon size="1.5em" name="report_problem" />
              <span> No Data Found </span>
            </div>
          </div>
        </q-card-section>
        <div class="row justify-between q-mt-sm">
          <div class="q-ml-lg text-grey-8" style="margin-top: 8px">
            <b>Tip: &nbsp;</b> Press &nbsp;<span class="fullscreenHintText">
              Shift + F</span
            >
            &nbsp; {{ toggleFullscreenTip }}
          </div>
          <div class="flex" style="font-size: 12px; margin-top: 0px">
            <div
              class="cursor-pointer q-mr-sm q-pa-sm"
              style="font-size: 12px"
              dense
              color="primary"
            >
              Records per page&nbsp;:&nbsp;
              <q-btn
                style="font-size: 12px"
                class="btn--no-hover q-pa-none"
                flat
                dense
                :label="
                  pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage
                "
                icon-right="fa-solid fa-caret-down"
                color="primary"
              >
                <q-menu>
                  <q-list
                    dense
                    v-for="(pag, index) in paginationOptions"
                    :key="index"
                  >
                    <q-item
                      clickable
                      v-close-popup
                      @click="selectpagination(pag)"
                      class="q-pa-none"
                    >
                      <q-item-section> {{ pag }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
            <q-pagination
              v-model="pagination.page"
              :max="pagesNumber"
              input
              class="paginationclass q-mr-sm"
              size="sm"
              direction-links
              boundary-links
              icon-first="skip_previous"
              icon-last="skip_next"
              icon-prev="fast_rewind"
              icon-next="fast_forward"
            />
          </div>
        </div>
      </q-card>
      <q-dialog v-model="confirm" persistent>
        <q-card class="gdialog card-outline-primary confirm-popup">
          <q-card-section class="card-header-section1">
            <div v-if="resetconfirm" class="text-h6 q-ml-sm">
              <q-icon
                name="report_problem"
                flat
                dense
                color="warning"
                size="25px"
                class="q-mb-xs"
              ></q-icon
              ><span class="q-ml-sm">Refresh Notifications</span>
            </div>
            <div v-else class="text-h6 q-ml-sm">
              <q-icon
                name="report_problem"
                flat
                dense
                color="warning"
                size="25px"
                class="q-mb-xs"
              ></q-icon
              ><span class="q-ml-sm">Mark all as read </span>
            </div>
          </q-card-section>

          <q-card-section class="">
            <span v-if="resetconfirm" class="">
              Are you sure you want to Refresh the Notifications ?</span
            >
            <span v-else class="">
              Are you sure you want to Mark all read ?</span
            >
          </q-card-section>
          <q-card-actions align="right" class="card-footer-section">
            <q-btn
              v-if="resetconfirm"
              label="Ok"
              outline
              color="secondary"
              @click="resetNotify()"
            />
            <q-btn
              v-else
              label="Ok"
              outline
              color="secondary"
              @click="updateReadAll()"
            >
            </q-btn>
            <q-btn label="Cancel" outline color="secondary" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { NotifyService, AlertService, UploadService } from "@/services";
import moment from "moment";
import { SpinnerComponent, commonFunction } from "@/utils";
import { useMyStore } from "@/store";
import { AppFullscreen, Loading } from "quasar";

export default {
  setup() {
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });
    const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, "All"]);
    const myStore = useMyStore();
    return {
      allNotification: ref([]),
      unreadNotification: ref([]),
      sortDirection: ref(null),
      sortBy: ref(""),
      myStore,
      pagination,
      paginationOptions,
      isSearchOpen: ref(false),
      fullscreenMode: ref(false),
      confirm: ref(false),
      loader: ref(false),
      resetconfirm: ref(false),
      search: ref(""),
    };
  },
  components: {
    SpinnerComponent,
  },
  watch: {
    refreshpricing(newval) {
      if (newval) {
        this.getNotifyAll();
      }
    },
  },
  computed: {
    unreadCount() {
      return this.allNotification.filter(
        (notification) => !notification.is_read
      ).length;
    },
    toggleicon() {
      if (this.sortDirection == "asc") {
        return "arrow_upward";
      } else {
        return "arrow_downward";
      }
    },
    toggleClassFullscreen() {
      if (AppFullscreen.isActive) {
        return "fullscreenHeightNotify my-sticky-header-table ";
      } else {
        return "TableHeightNotify my-sticky-header-table ";
      }
    },
    toggleFullscreenTip() {
      if (AppFullscreen.isActive) {
        return "to exit Fullscreen ";
      } else {
        return "to enter Fullscreen";
      }
    },
    toggleFullscreenicon() {
      if (AppFullscreen.isActive) {
        return "fullscreen_exit";
      } else {
        return "fullscreen";
      }
    },
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    Notifyheader() {
      return this.myStore.fields.NotifyFields;
    },
    pagesNumber() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.allNotification.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.paginatedData.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    data() {
      if (this.pagination.rowsPerPage == -1) {
        return this.paginatedData;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.paginatedData.slice(startIndex, endIndex);
      }
    },
    paginatedData() {
      const searchTerm = this.search.toLowerCase();
      if (searchTerm !== null && searchTerm !== undefined && searchTerm != "") {
        return this.allNotification.filter((row) => {
          this.pagination.page = 1;
          const bomcheck =
            row.more_info.bom_name &&
            row.more_info.bom_name.toLowerCase().includes(searchTerm);
          const message =
            row.message && row.message.toLowerCase().includes(searchTerm);
          const ipnCheck =
            row.more_info.ipn &&
            row.more_info.ipn.toLowerCase().includes(searchTerm);

          return bomcheck || message || ipnCheck;
        });
      } else if (this.sortDirection != null) {
        const sorted = [...this.allNotification];
        sorted.sort((a, b) => {
          const labelA = a.more_info[this.sortBy]
            ? a.more_info[this.sortBy].toLowerCase()
            : "";
          const labelB = b.more_info[this.sortBy]
            ? b.more_info[this.sortBy].toLowerCase()
            : "";
          const dateA = new Date(a[this.sortBy]);
          const dateB = new Date(b[this.sortBy]);
          if (this.sortBy != "created_at") {
            if (this.sortDirection === "asc") {
              return labelA.localeCompare(labelB);
            } else if (this.sortDirection === "desc") {
              return labelB.localeCompare(labelA);
            } else {
              return this.allNotification;
            }
          } else if (this.sortBy == "created_at") {
            if (this.sortDirection === "asc") {
              return dateA - dateB;
            } else if (this.sortDirection === "desc") {
              return dateB - dateA;
            } else {
              return this.allNotification;
            }
          } else {
            return this.allNotification;
          }
        });
        return sorted;
      } else {
        return this.allNotification;
      }
    },
  },
  methods: {
    async getNotifyAll(queryParams, is_watchlist) {
      this.loader = true;
      if (queryParams && !is_watchlist) {
        const [allnotify, unreadNotify, Cunread] = await Promise.all([
          NotifyService.getNotification(queryParams.bom_name, queryParams.ipn),
          NotifyService.UnreadNotification(),
          NotifyService.Notificationcount(),
        ]);

        this.allNotification = allnotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );

        this.unreadNotification = unreadNotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );
        this.myStore.unreadcount = Cunread;
      } else if (queryParams && is_watchlist) {
        const [allnotify, unreadNotify, Cunread] = await Promise.all([
          NotifyService.getNotification(
            undefined,
            queryParams.ipn,
            queryParams.mpn,
            is_watchlist
          ),
          NotifyService.UnreadNotification(),
          NotifyService.Notificationcount(),
        ]);

        this.allNotification = allnotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );

        this.unreadNotification = unreadNotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );

        this.myStore.unreadcount = Cunread;
      }

      this.loader = false;
    },
    formatDate(date) {
      var d = moment.utc(date).utcOffset("+05:30").fromNow();
      return d;
    },
    cleanupWords(message) {
      const words = commonFunction.descriptionFormat(message);
      return words;
    },
    selectpagination(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.pagination.rowsPerPage = -1;
      } else {
        this.pagination.rowsPerPage = parseInt(value);
      }
      this.pagination.page = 1;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    closeSearch() {
      this.search = "";
      this.isSearchOpen = false;
    },
    openconfirm(type) {
      if (type == "mark") {
        this.resetconfirm = false;
      } else {
        this.resetconfirm = true;
      }
      this.confirm = true;
    },
    resetNotify() {
      this.search = "";
      this.sortDirection = null;
      this.isSearchOpen = false;
      this.pagination = { rowsPerPage: 25, page: 1 };
      if (AppFullscreen.isActive) {
        this.toggleFullscreen1();
      }
      if (this.queryParams) {
        this.getNotifyAll(this.queryParams, this.flag);
      } else {
        this.getNotifyAll();
      }

      this.confirm = false;
    },
    customSort(column) {
      this.search = "";
      this.sortBy = column.key;
      if (this.sortDirection == null) {
        column.sortDirect = "asc";
        this.sortDirection = "asc";
      } else if (this.sortDirection == "asc") {
        this.sortDirection = "desc";
        column.sortDirect = "desc";
      } else {
        column.sortDirect = null;
        this.sortDirection = null;
      }
    },
    async updateReadAll() {
      if (this.myStore.unreadcount > 0) {
        this.resetconfirm = false;
        this.myStore.unreadcount = 0;
        const body = {
          uids: this.unreadNotification.map((msg) => msg.uid),
        };
        await NotifyService.markasAllRead(body).then((x) => {
          console.log(x);
        });
        this.allNotification.map((unread) => {
          unread.read = true;
        });
        AlertService.Success("All Notifications are  Marked as Read ");
        this.myStore.refreshquotes = Math.random();
        await this.getNotifyAll();
        this.confirm = false;
      } else {
        AlertService.Error(
          "All notifications have already been marked as read."
        );
        this.confirm = false;
      }
    },
    async updateRead(data, index) {
      if (!data.is_read) {
        // var treeNode = []
        this.myStore.unreadcount =
          this.myStore.unreadcount > 0 ? this.myStore.unreadcount - 1 : 0;
        this.allNotification[index].read = true;
        await NotifyService.markasRead(data.uid).then((x) => {
          console.log(x);
        });
        AlertService.Success("Notification has Marked as Read");
        if (data.more_info.bom_name) {
          this.selectingBomIpn(data);
        }
        await this.getNotifyAll(this.queryParams, this.flag);
        // }, 5000)
      } else {
        AlertService.Error("Notification Already Marked as Read");
        if (data.more_info.bom_name) {
          this.selectingBomIpn(data);
        }
      }
    },
    async selectingBomIpn(data) {
      var treeNode = [];
      Loading.show();
      await UploadService.getStaticTree().then((x) => {
        treeNode = x;
        const iterationNames = treeNode.flatMap((oem) =>
          oem.children.flatMap((product) =>
            product.children
              .filter((child) => child.type === "iteration")
              .map((iteration) => iteration.name)
          )
        );
        if (iterationNames.includes(data.more_info.bom_name)) {
          const resultNodes = commonFunction.newfindoemproduct(
            treeNode,
            data.more_info.bom_name
          );
          if (resultNodes) {
            var r1 = [];
            r1.push(resultNodes.oemName), r1.push(resultNodes.productName);
            localStorage.setItem("bom_name", resultNodes.iterationName);
            localStorage.setItem("expandedNodes", JSON.stringify(r1));
            localStorage.setItem("qty", null);
            localStorage.setItem("ipn", null);
            var quotes = {
              GroupBy: "none",
              GroupName: "None",
              Filter: { name: "All", value: "all", id: 1, selected: false },
              Filter_Field: "",
            };
            localStorage.setItem("quotespage", JSON.stringify(quotes));
            this.myStore.loadQuotes();
            this.myStore.refreshquotes = Math.random();
            Loading.hide();
            this.$router.push(
              this.myStore.userdata.role == "buyer" ? "/partlist" : "/quotes"
            );
          } else {
            console.log("No data found for the given iteration name.");
          }
        } else {
          Loading.hide();
          AlertService.Error("Item Not Found");
          this.myStore.refreshquotes = Math.random();
        }
      });
    },
    handleKeyDown(event) {
      //Keyboard shortcuts
      if (event.shiftKey && event.keyCode === 70) {
        event.preventDefault();
        this.toggleFullscreen1();
      }
    },
    toggleFullscreen1() {
      this.fullscreenMode = !this.fullscreenMode;
      const target = this.$refs.fullscreenTarget.$el;
      AppFullscreen.toggle(target)
        .then(() => {
          // success!
        })
        .catch((err) => {
          alert(err);
        });
    },
    truncateMessage(message) {
      const maxLength = 200;
      if (message.length > maxLength) {
        let tMessage = message.substring(0, maxLength);
        const lastSpace = tMessage.lastIndexOf(" ");
        if (lastSpace > 0) {
          return tMessage.substring(0, lastSpace) + "...";
        }
      }
      return message;
    },
    open(item, flag) {
      this.dialog = true;
      this.queryParams = item;
      this.flag = flag;
      this.getNotifyAll(item, flag);
    },

    clearDialogData() {
      this.dialog = false;
    },
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  data() {
    return {
      queryParams: "",
      flag: "",
      dialog: false,
    };
  },
};
</script>
<style scoped lang="scss">
.page-heading {
  margin-bottom: 10px;
}
.notifyTable .glyphicon {
  visibility: hidden;
}
.notifyTable tr:hover .glyphicon {
  visibility: visible;
}
.card-box {
  border-radius: 10px;
}
.notifyhead {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.action-flex {
  display: flex;
  gap: 10px;
  align-items: center;
}
.unread-icon-btn {
  background-color: #ffeeb1;
  box-shadow: 0px 3px 4px rgba(205, 171, 49, 0.38);
  color: #755d08;
}
.read-icon-btn {
  background-color: #d6faff;
  box-shadow: 0px 3px 4px rgba(6, 144, 189, 0.21);
  color: #007b8b;
}
.message-wrapper {
  white-space: normal;
  overflow-wrap: break-word;
}
.fullscreen-active {
  background-color: white;
  border-radius: 0;
}
.part-detail-title {
  border-bottom: solid 1px #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
</style>
