<template>
    <q-form @submit="createTag()">
        <q-card class="gdialog">
            <q-card-section class="q-pa-none">
                <q-bar class="bg-primary text-white">
                    <div>Create Watchlist Tag Name</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip class=" text-primary">Close</q-tooltip>
                    </q-btn>
                </q-bar>

            </q-card-section>

            <q-card-section class="q-px-md">
                <q-input class="q-py-md" filled  
                    v-model="tag_name" :rules="TagNameValidation"
                    label="Tag Name *" />
                <q-input class="q-py-md"  filled  
                    v-model="sourcing_period" :rules="TagNameValidation"
                    label="Sourcing Period *" />
            </q-card-section>
            <q-card-actions align="right">
                <q-btn type="submit" class="" outline color="secondary" label="Create Tag" icon="fas fa-add" />
                <q-btn label="Cancel" outline color="secondary" v-close-popup />
            </q-card-actions>

        </q-card>   
    </q-form>
</template>
<script>
// import { useMyStore } from '@/store';
import { AlertService, AlterPartService } from '@/services';
import {ref} from 'vue'
// import { AlertService, UploadService } from '@/services'
export default {
    setup() {
        // const myStore = useMyStore();
        const TagNameValidation = [
            (v) => !!v || 'Field is required',
        ]
        return {
            // myStore,
            TagNameValidation,
            tag_name:ref(''),
            sourcing_period:ref()
        }
    },
props:["closeDialog"],
    methods: {
      async createTag(){
        const body={
            "tag_name":this.tag_name,
            "sourcing_period":this.sourcing_period
        }
       await AlterPartService.createtagname(body).then((x)=>{
        AlertService.Success(x);
        this.closeDialog();
       }).catch((err)=>{AlertService.Error(err)})     
            
        },
       
    }
}
</script>