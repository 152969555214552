// import { useAuthStore } from '@/stores';
import Notify from 'quasar/src/plugins/Notify.js';;

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

// let config = {
//     method: 'get',
//     maxBodyLength: Infinity,
//     url: 'http://vuelogix.sca.humalitix.com:4005/api/oem/{customer_name}/product/{product_name}/iteration/I1',
//     headers: {
//       'accept': 'application/json',
//       'X-API-Key': 'vlx_sc_test_api_key_20231220_4005'
//     }
//   };

function request(method) {
  var cmuser2 = JSON.parse(localStorage.getItem("cmuser")) || {
    access_token: null,
  };

  //if(cmuser.access_token){

  return (url, body) => {
    const requestOptions = {
      method,
      // headers: authHeader(url)
    };
    if (body) {
      requestOptions.headers = {
        "Content-Type": "application/json",
        "X-API-Key": cmuser2.access_token,
      };
      requestOptions.body = JSON.stringify(body);
    } else {
      requestOptions.headers = {
        "Content-Type": "application/json",
        "X-API-Key": cmuser2.access_token,
      };
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
  // }
}

// function request2(method) {
//     return (url, body) => {
//         const requestOptions = {
//             method,
//             // headers: authHeader(url)
//         };
//         if (body) {
//             requestOptions.headers={'Content-Type':'application/json'};
//             requestOptions.body = JSON.stringify(body);
//         }
//         return fetch(url, requestOptions).then(handleResponse);
//     }
// }

// helper functions

// function authHeader(url) {
//     // return auth header with basic auth credentials if user is logged in and request is to the api url
//     // const { user } = useAuthStore();
//     // const isLoggedIn = !!user?.authdata;
//     // const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
//     // if (isLoggedIn && isApiUrl) {
//     //     return { Authorization: `Basic ${user.authdata}` };
//     // } else {
//     //     return {};
//     // }
// }

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    // console.log("*****response.data:",response.data);
    if (!response.ok) {
      // const { user, logout } = useAuthStore();
      // if ([401, 403].includes(response.status) && user) {
      //     // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //     logout();
      // }

      //  newdata =data.details  const data = text && JSON.parse(JSON.stringify(text));

      const myJSON = JSON.parse(JSON.stringify(data)) || { detail: "" };

      const error = (data && data.message) || response.statusText;
      // console.log("*****response.statusText:",response.statusText,data,myJSON.detail);
      if (myJSON.detail == "Invalid API key") {
        localStorage.removeItem("cmuser");
        Notify.create({
          message: "Session Expired Please login again.",
          icon: "error",

          color: "negative",
          position: "center",
          multiLine: true,
        });
        setTimeout(() => {
          location.reload();
        }, "2000");
        //return Promise.reject("Invalid API key");
      } else if (myJSON.detail) {
        return Promise.reject(myJSON.detail);
      } else {
        return Promise.reject(error);
      }
    }

    return data;
  });
}
