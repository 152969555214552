<template>
    <div class="gdialog ">
        <div class="q-pa-none move-header q-pb-sm">
            <h6 class="title-md" v-if="bomType[0]==='iteration'">Move "{{ bom }}"</h6>
            <h6 class="title-md" v-if="bomType[0]==='product'">Move "{{ bomType[1] }}"</h6>
        </div>

        <div class="move-header flex  move-breadcrumb ">
            Current Location:
            <q-breadcrumbs v-if="bomType[0]==='iteration'" class="text-primary q-ml-sm " active-color="primary">
                <template v-slot:separator>
                    <q-icon size="1.2em" name="arrow_forward" color="secondary" />
                </template>

                <q-breadcrumbs-el :label="currentNodes[0]" icon="factory" />
                <q-breadcrumbs-el :label="currentNodes[1]" icon="book" />
                <q-breadcrumbs-el :label="bom" icon="login" />
            </q-breadcrumbs>
            <q-breadcrumbs v-if="bomType[0]==='product'" class="text-primary q-ml-sm " active-color="primary">
                <template v-slot:separator>
                    <q-icon size="1.2em" name="arrow_forward" color="secondary" />
                </template>

                <q-breadcrumbs-el :label="currentoem" icon="factory" />
                <q-breadcrumbs-el :label="bomType[1] " icon="book" />
                <!-- <q-breadcrumbs-el :label="bom" icon="login" /> -->
            </q-breadcrumbs>
        </div>
        <div class="move-to flex  move-breadcrumb">
            Move To :
            <q-breadcrumbs v-if="bomType[0]==='iteration'" class="  q-ml-sm">
                <template v-slot:separator>
                    <q-icon size="10px" name="fa-solid fa-angles-right" color="secondary" />
                </template>

                <q-breadcrumbs-el :label="myStore.NOEM" icon="factory" />
                <q-breadcrumbs-el v-if="myStore.NProduct.product" :label="myStore.NProduct.product" icon="book" />

            </q-breadcrumbs>
            <q-breadcrumbs v-if="bomType[0]==='product'" class="  q-ml-sm">
                <template v-slot:separator>
                    <q-icon size="10px" name="fa-solid fa-angles-right" color="secondary" />
                </template>

                <q-breadcrumbs-el :label="myStore.NOEM" icon="factory" />

            </q-breadcrumbs>

        </div>

        <q-card class="move-popup-card  q-mt-md" bordered>
            <!-- <hr class="line-horizontal"/> -->
            <q-card-section class="q-pa-none move-popup-card">
                <q-card-actions class=" bg-primary text-white move-popup-header">
                    <span align="left" class="q-py-xs">Document Explorer </span>
                    <q-space />
                </q-card-actions>
                <q-scroll-area style="height:250px;">
                    <div class="q-px-md">
                        <BomTree type="popup" />
                    </div>

                </q-scroll-area>
            </q-card-section>
        </q-card>
        <q-card-actions align="right" class="q-mb-md q-mr-xl">
            <q-btn :loading="loading" label="Move" class="quote-btn" outline @click="MoveRfq()">
                <template v-slot:loading>
                    <q-spinner-facebook />
                </template>
            </q-btn>
            <q-btn  label="Cancel" v-close-popup class="quote-btn" outline />
        </q-card-actions>

    </div>

    <q-dialog v-model="confirm"  >
    <q-card class="gdialog card-outline-primary confirm-popup">

      <q-card-section  class="card-header-section1">
        <div v-if="bomType[0] ==='iteration'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Move {{  myStore.partListStatus==false?this.myStore.treeLabels[2]: this.myStore.treeLabels[3] }} Confirmation</span></div>
        <div v-if="bomType[0] ==='product'" class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Move Assembly Confirmation</span></div>
      </q-card-section>

      <q-card-section class="">

        <span v-if="bomType[0] ==='iteration'" class="">Are you sure, you want to move {{  myStore.partListStatus==false?this.myStore.treeLabels[2]: this.myStore.treeLabels[3] }}   {{ bom }} ?</span>
        <span v-if="bomType[0] ==='product'" class="">Are you sure, you want to move Assembly {{ bomType[1] }} ?</span>
      
      </q-card-section>
      <q-card-actions  align="right">

        <q-btn label="Ok" class="quote-btn" @click="handleConfirm" />
        <q-btn label="Cancel" class="quote-btn" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>

import { defineComponent, defineAsyncComponent, ref } from 'vue';
// import BomTree from './BomTree.vue';
import { useMyStore } from '@/store';
import { AlertService, EmailService } from '@/services';


export default defineComponent({
    name: 'app',
    components: {

        // BomTree,
        BomTree: defineAsyncComponent(() => import('./PopupTree.vue'))

    },
    // props: ["Type", "popupType", "editNode", "node", "close", "addClose","popOEm","popProduct","popIter"],
    props: {
        commonpopupType: { type: String },
        close: { type: String },
        bomType: { type: Array },
        currentoem: { type: Array },


    },
    setup() {
        const myStore = useMyStore();

        return {
            myStore,
            loading: ref(false),
            confirm: ref(false),
        }
    },

    watch: {
        'myStore.NOEM': function (newOem) {
            if (newOem) {
                this.myStore.NProduct.product = "";

            }
        },


    },
    computed: {

        currentNodes() {
            const oemExpand = JSON.parse(localStorage.getItem('expandedNodes'))
            return oemExpand
        },
        bom() {
            const rfq = localStorage.getItem('bom_name')
            return rfq
        }
    },
    created() {

    },
    methods: {

        MoveRfq() {
       this.confirm=true
    },
    handleConfirm(){
        this.confirm=false
        if(this.bomType[0] === 'iteration'){
            if (this.myStore.NProduct.product === '') {
                AlertService.Error(`Please Select a ${this.myStore.treeLabels[1]} to Move ${ this.myStore.userdata.role == "quoter"? this.myStore.treeLabels[2]: this.myStore.treeLabels[3]}`)
            } else if(this.myStore.NProduct.product===this.currentNodes[1]){
                    AlertService.Error(`Source And Destination ${this.myStore.treeLabels[1]} is Same`)
            }  else {
                this.loading = true
                setTimeout(() => {
                    EmailService.moveRfqFun(this.bom, this.myStore.NProduct.product).then((x) => {
                        AlertService.Success(x);
                        this.myStore.refreshpricing = Math.random();
                        this.close()
                        this.loading = false
                    }).catch((err) => { AlertService.Error(err);this.loading=false })
                },'1000')
            }
        }else{
            if (this.myStore.NOEM === '') {
                AlertService.Error(`Please Select a ${this.myStore.treeLabels[0]} to Move ${this.myStore.treeLabels[1]}`)
            } else if(this.myStore.NOEM && this.myStore.NOEM === this.currentoem){
                AlertService.Error(`Source And Destination ${this.myStore.treeLabels[0]} is Same`)
            }
            else {
                this.loading = true
                setTimeout(() => {
                    EmailService.moveAssemblyFun(this.bomType[1], this.myStore.NOEM).then((x) => {
                        AlertService.Success(x);
                        this.myStore.refreshpricing = Math.random();
                        this.close()
                        this.loading = false
                    }).catch((err) => { AlertService.Error(err);this.loading=false })
                },'1000')
            }
        }
    }
    },
    mounted() {

    }

})



</script>