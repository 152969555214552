<template>
  <q-page class="q-pa-sm">
  <div class="page-heading q-pa-sm">Settings</div>
   


  <q-card class="q-mt-lg no-shadow" bordered>
      <q-card-section class="text-h6  ">

        <q-item>
          <q-item-section avatar class="">
            <q-icon color="secondary" name="fas fa-info-circle" size="44px"/>
          </q-item-section>

          <q-item-section>
            <div class="text-h6">Coming Soon..</div>
          </q-item-section>
        </q-item>

      
     
        
    </q-card-section>
    </q-card>
  </q-page>
</template>
  
<script>

import { ref } from 'vue';
import { QuotesService } from '../services'
// import SuperSet from '@/components/quotessummary/SuperSet.vue'
// import RightMenu from '@/components/quotessummary/RightMenu.vue'

import { embedDashboard } from "@superset-ui/embedded-sdk";
// import BomTree from '@/components/BomTree.vue'
//import FilterTab from '@/components/filters/FilterTab.vue'
import { useMyStore } from '@/store';

export default {
  name: 'SettingsPage',

  setup() {
    const isCardVisible = ref(false);
    const closeCard = () => {
      isCardVisible.value = false;
    };
    const myStore = useMyStore();



    return {
      myStore,
      isCardVisible,
      closeCard,
      selected: ref(localStorage.getItem("bom_name")),
      ipn: ref(''),
      LeftTab: ref('filters'),
      dataview: ref(null),
      dashboardUrl: 'https://superset.datatest.ch/superset/dashboard/p/V5nK5eRBK10/',
      dashboardUrl2: 'https://superset.datatest.ch/superset/dashboard/p/D5ej1052Kyn/',
      // dashid1:"db28771a-383b-4385-a80f-3d4006a146e4",
      // dashid: ref("0dc2c46f-48b5-45be-8a58-c02b9a5fc772"),
      dashid: ref(localStorage.getItem('did')),
      //dashidnew: ref(null)

    }
  }, components: {
    // BomTree,
    // FilterTab,
    // RightMenu,
    // SuperSet

  },


  methods: {
    fdashid(dashid) {
      this.dashid = dashid;
      //  this.findLocalData();

    },
    async findLocalData() {

      let myData = localStorage.getItem("superset_token"); console.log("superset_token:-", myData);
      if (myData == null) {
        var token = await QuotesService.getToken().then((x) => {
          console.log("getToken:-", x.data.access_token);
          return x.data.access_token;
        });
        //getTokennew
        var tokeng = await QuotesService.getGuestToken(token, this.dashid).then((x) => {
          console.log("GGToken:-", x.data);
          return x.data.token;
        });
        console.log("guest token:", tokeng)

        // await QuotesService.getChart(token).then((x) => {
        //    console.log("getChart:",x);
        //    this.dataview = x.data.result;
        // });

        console.log("token:", token);
        embedDashboard({
          id: this.dashid, // given by the Superset embedding UI
          supersetDomain: "http://vuelogix.sca.humalitix.com:8088",
          mountPoint: document.getElementById("my-superset-container"), // any html element that can contain an iframe
          // fetchGuestToken: () => fetchGuestTokenFromBackend(),
          fetchGuestToken: () => tokeng,
          dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
            hideTitle: true,
            filters: {
              expanded: true,
            }
          },
        });






      } else {
        return "";
      }



    },
  },
  mounted() {

    this.findLocalData();
  },

}
</script>
  