<template>
  <q-page class="q-pr-sm q-pl-md layout">
    <div class="page-header">
      <div class="page-heading">Documents</div>
      <span class="page-headerBreadcrumb q-ml-sm">
        <q-breadcrumbs
          class="qsummarybread"
          v-if="titledata[0] && titledata[1] && titledata[2]"
        >
          <q-breadcrumbs-el :label="titledata[0]" icon="factory">
          </q-breadcrumbs-el>
          <q-breadcrumbs-el :label="titledata[1]" icon="book">
          </q-breadcrumbs-el>
          <q-breadcrumbs-el
            @click="showTree()"
            :label="titledata[2]"
            icon="login"
            class="text-info1 cursor-pointer"
          >
          </q-breadcrumbs-el>
        </q-breadcrumbs>
      </span>
    </div>

    <header class="header-wrapper">
      <aside class="toaster-wrap">
        <FileToasters
          type="upload"
          :files="files"
          :lastuploaddate="lastuploaddate"
          :totaldata="totaldata"
          :FileSuffix="FileSuffix"
          :errboms="errboms"
        />
      </aside>
      <aside class="upload-wrap uploadsection" v-if="$q.screen.gt.xs">
        <FileUpload :bom_name="selected" @filepicked="filepicked" />
      </aside>
    </header>

    <div class="col">
      <!-- <div class="row flex">
                    <div class="col-8">
                        <FileToasters type="upload" :files="files" :lastuploaddate="lastuploaddate"
                            :totaldata="totaldata" :FileSuffix="FileSuffix" :errboms="errboms" />
                    </div>
               
                <div class="col-4  uploadsection q-px-lg  " v-if="$q.screen.gt.xs">
                    <FileUpload :bom_name="selected" @filepicked="filepicked" />
                </div>
            </div> -->

      <section class="top-btns-wrap">
        <q-btn-dropdown
          outline
          class="btn-fixed-width quote-btn"
          color="secondary"
          label="Create New"
          icon="add"
        >
          <q-list dense>
            <q-item clickable v-close-popup @click="openpopup('oem')">
              <q-item-section>
                <q-item-label> {{ this.myStore.treeLabels[0] }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="openpopup('product')">
              <q-item-section>
                <q-item-label> {{ this.myStore.treeLabels[1] }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="openpopup('iteration')">
              <q-item-section>
                <q-item-label>
                  {{
                    myStore.userdata.role == "quoter"
                      ? this.myStore.treeLabels[2]
                      : this.myStore.treeLabels[3]
                  }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn
          :to="myStore.userdata.role == 'buyer' ? '/partlist' : '/quotes'"
          outline
          color="secondary"
          :label="`Go to ${this.myStore.CommonTitle.PageTitle.title}`"
          :disable="lhidequotes"
          icon="fas fa-arrow-right"
          class="btn-fixed-width quote-btn"
        />
      </section>

      <div class="row flex">
        <div class="col-3 column-height" v-if="isCardVisible">
          <q-card class="treecard" flat>
            <div
              class="q-mini-drawer-hide absolute lefticon-outer"
              style="top: 100px; right: -17px; z-index: 99"
              v-if="isCardVisible == true"
            >
              <q-btn
                dense
                round
                unelevated
                color="primary"
                icon="fa-solid fa-angle-left"
                @click="isCardVisible = false"
                class="lefticon glossy"
              />
            </div>
            <q-tabs
              class="no-shadow flat filter-tab-wrap"
              v-model="leftTab"
              :switch-indicator="true"
              align="justify"
              narrow-indicator
              active-bg-color="primary text-white"
              indicator-color="primary"
            >
              <q-tab
                name="products"
                icon="account_tree"
                label="Explorer"
                class="curved-tabs"
              />
            </q-tabs>
            <q-separator />
            <q-tab-panels
              v-model="leftTab"
              animated
              class="text-dark text-center"
            >
              <q-tab-panel name="products" class="q-pa-none">
                <bom-tree
                  @changeBom_name="getBom_name"
                  type="upload"
                  :popup="dialog"
                  :newCreatedData="newCreatedData"
                />
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>

        <div
          :class="{
            'col-9 column-height': isCardVisible == true,
            ' col-12 column-height': isCardVisible == false,
          }"
        >
          <q-card class="q-ml-sm treecard">
            <div
              class="q-mini-drawer-hide absolute lefticon-outer"
              style="top: 100px; left: -17px; z-index: 99"
              v-if="isCardVisible == false"
            >
              <q-btn
                dense
                round
                unelevated
                color="primary"
                icon="fa-solid fa-angle-right"
                @click="isCardVisible = true"
                class="lefticon glossy"
              />
            </div>
            <q-tabs
              class="filter-tab-wrap"
              v-model="Tab1"
              :switch-indicator="true"
              indicator-color="primary"
              align="justify"
              narrow-indicator
              active-bg-color="primary text-white "
              active-class="TabStyle"
            >
              <q-tab
                name="quotes"
                icon="gavel"
                :label="this.myStore.CommonTitle.PageTitle.title"
                class=""
              />
              <q-tab
                name="orders"
                disable
                label="Orders"
                icon="list_alt"
                class=""
              />
              <q-tab
                name="tracking"
                disable
                label="Tracking"
                icon="data_exploration"
                class=""
              />
              <q-tab
                name="inventory"
                disable
                label="Inventory"
                icon="inventory_2"
                class=""
              />
            </q-tabs>
            <q-separator />
            <q-tab-panels v-model="Tab1" animated class="text-dark text-center">
              <q-tab-panel name="quotes" class="">
                <FileList
                  :bom_name="selected"
                  @Toasterfunction="Toaster"
                  @hidequotes1="fhidequotes"
                  :receivefile="receivefile"
                  @fileDeleted="fileDeleted"
                  @ErrorCount="ErrorCount"
                />
              </q-tab-panel>

              <q-tab-panel name="orders" class="q-pa-xs">
                <FileList
                  :bom_name="selected"
                  @Toasterfunction="Toaster"
                  @hidequotes1="fhidequotes"
                />
              </q-tab-panel>
              <q-tab-panel name="tracking" class="q-pa-xs"> </q-tab-panel>
              <q-tab-panel name="inventory" class="q-pa-xs"> </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>

  <q-dialog v-model="dialog" persistent>
    <div style="overflow-x: hidden; width: 700px; max-width: 80vw">
      <PopupComponent
        :Type="single"
        :addClose="closeDialog"
        @created="created"
      />
    </div>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import BomTree from "@/components/BomTree.vue";
import PopupComponent from "@/components/PopupComponent.vue";
import FileToasters from "@/components/upload/FileToasters.vue";
import FileList from "@/components/upload/FileList.vue";
import FileUpload from "@/components/upload/FileUpload.vue";
import { useMyStore } from "@/store";
import { commonFunction } from "@/utils";

export default {
  setup() {
    const myStore = useMyStore();
    const isCardVisible = ref(true);
    const closeCard = () => {
      isCardVisible.value = false;
    };
    return {
      myStore,
      isCardVisible,
      closeCard,
      Tab1: ref("quotes"),
      leftTab: ref("products"),
      dialog: ref(false),
      selected: ref(localStorage.getItem("bom_name")),
      files: ref(0),
      lastuploaddate: ref(),
      ToatalMB: ref(),
      totaldata: ref(0),
      lhidequotes: ref(null),
      receivefile: ref(""),
      receiveDeletefile: ref(""),
      FileSuffix: ref(""),
      newCreatedData: ref(""),
      errboms: ref(0),
    };
  },
  components: {
    BomTree,
    FileList,
    FileUpload,
    PopupComponent,
    FileToasters,
  },
  computed: {
    titledata() {
      var oemExpand = [];
      if (this.selected != null) {
        oemExpand = JSON.parse(localStorage.getItem("expandedNodes"));
        oemExpand.push(this.selected);
        return oemExpand;
      } else {
        return ["", "", ""];
      }
    },
  },
  methods: {
    // receiving upload file
    filepicked(fileName) {
      this.receivefile = fileName;
    },
    formattedString(string) {
      if (string != null && string != undefined) {
        const res = commonFunction.BreadcrumbFormat(string);
        return res;
      } else {
        return "";
      }
    },
    // receiving deleted File
    fileDeleted(delFile) {
      this.receiveDeletefile = delFile;
      if (this.receiveDeletefile) {
        this.receivefile = "";
      }
    },
    // receiving newly created Node
    created(createdData) {
      this.newCreatedData = createdData;
      this.isCardVisible = true;
    },
    // open the create new popup
    openpopup(type) {
      this.single = type;
      this.dialog = true;
    },
    //  get Bom name
    getBom_name(selected) {
      this.selected = selected;
    },
    // receive Extract File
    fhidequotes(hidequotes) {
      this.lhidequotes = hidequotes;
    },
    ErrorCount(err) {
      if (err > 0) {
        this.errboms = err;
      } else {
        this.errboms = 0;
      }
    },
    // get Toaster details from Files
    Toaster(toaster) {
      this.files = toaster.length;
      if (toaster.length > 0) {
        this.lastuploaddate = toaster[0].created_at;
      } else {
        this.lastuploaddate = null;
      }
      this.ToatalMB = toaster.map((doc) => doc.document_size);
      var Size = this.ToatalMB.reduce((sum, item) => sum + item, 0);

      var i = Size == 0 ? 0 : Math.floor(Math.log(Size) / Math.log(1024));
      this.totaldata = (Size / Math.pow(1024, i)).toFixed(2) * 1;
      var suffix =
        (Size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i];
      this.FileSuffix = suffix.slice(-2);
      //  + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  mounted() {
    this.myStore.isCardVisible = true;
  },
};
</script>

<style scoped lang="scss">
.quote-btn {
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 10px;
}
.header-wrapper {
  display: grid;
  gap: 15px;
  grid-template-columns: 2fr 1fr;
}
.top-btns-wrap {
  display: flex;
  gap: 15px;
  // justify-content: flex-end;
  align-items: center;
  margin: 15px 0;
}
</style>
