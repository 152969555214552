import { fetchWrapper } from '@/helpers/fetch-wrapper';
import axios from 'axios'
import { API_BASE_URL ,LoginKey} from '@/api.config';
export const UserService = {
    getLogin,
    switchRoles,
    getRoles
}

    const apiUrl = `${API_BASE_URL}`;
    const Baseurl = '/api'

async function getLogin(username, passowrd,role) {
    let data = JSON.stringify({
        "username": username,
        "password": passowrd,
        "role":role
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/user/login`,
        headers: {
            'X-API-Key': `${LoginKey}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return (axios.request(config));



}
// Switch Roles
function switchRoles(new_role) {
    return fetchWrapper.post(`${apiUrl}/user/login/switch/role/${new_role}`)
}
// Get user Roles
function getRoles() {
    return fetchWrapper.get(`${Baseurl}/Roles.json`)
}