import { fetchWrapper } from "@/helpers";
import { API_BASE_URL } from "@/api.config";
const apiUrl2 = `${API_BASE_URL}`;

export const NotifyService = {
  Notificationcount,
  getNotification,
  getNotificationIPN,
  getNotificationMPN,
  UnreadNotification,
  markasAllRead,
  markasRead,
  enableDisableNotification,
};
// get Unread Notification Count
function Notificationcount() {
  return fetchWrapper.get(`${apiUrl2}/notifications/unread/count`);
}
// get All Notification
function getNotification(bom_name, ipn, mpn, is_watchlist) {
  if (bom_name != undefined) {
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.get(
      `${apiUrl2}/notifications/all?ipn=${newipn}&bom_name=${bom_name}`
    );
  } else if (ipn != undefined) {
    let newMpn = encodeURIComponent(encodeURIComponent(mpn));
    let newipn = encodeURIComponent(encodeURIComponent(ipn));
    return fetchWrapper.get(
      `${apiUrl2}/notifications/all?ipn=${newipn}&mpn=${newMpn}&is_watchlist=${is_watchlist}`
    );
  } else {
    return fetchWrapper.get(`${apiUrl2}/notifications/all`);
  }
}
//get Notification IPN & MPN
function getNotificationIPN(ipn) {
  let newipn = encodeURIComponent(encodeURIComponent(ipn));
  return fetchWrapper.get(`${apiUrl2}/notifications/all?ipn=${newipn}`);
}
//get Notification MPN
function getNotificationMPN(mpn) {
  let newmpn = encodeURIComponent(encodeURIComponent(mpn));
  return fetchWrapper.get(`${apiUrl2}/notifications/all?mpn=${newmpn}`);
}
// get Unread Notification
function UnreadNotification() {
  return fetchWrapper.get(`${apiUrl2}/notifications/unread`);
}
//  Mark all as read
function markasAllRead(body) {
  return fetchWrapper.post(`${apiUrl2}/notifications/mark_all/as_read`, body);
}
//  Mark all as read
function markasRead(uid) {
  return fetchWrapper.post(`${apiUrl2}/notifications/mark/as_read/${uid}`);
}

function enableDisableNotification(iteration_name, flag) {
  return fetchWrapper.put(
    `${apiUrl2}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/notification/${flag}`
  );
}
