<template>
  <main id="home-wrap">
    <HomeView />
  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import HomeView from "./HomeView.vue";

export default {
  setup() {
    const tab = ref("dashboards");

    onMounted(() => {
      const savedTab = localStorage.getItem("activeTab");
      const fromNotifications = localStorage.getItem("fromNotifications");
      if (savedTab === "watchlist" && fromNotifications === "true") {
        tab.value = "watchlist";
        localStorage.removeItem("fromNotifications");
      } else {
        tab.value = "dashboards";
      }
    });
    return {
      tab,
    };
  },
  components: {
    HomeView,
  },
};
</script>
